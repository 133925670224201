<template>
  <div class="assessment-plans__step step2">
    <div class="assessment-plans__step__header">
      <div class="assessment-plans__step__title">Шаг 2. Настройка параметров оценки</div>
      <mt-page-nav
          :next-btn-loading="nextRequestPending"
          @back="back"
          @next="next"
      >
        <template #back>
          Назад
        </template>
        <template #next>
          Далее
        </template>
      </mt-page-nav>
    </div>
    <div class="assessment-plans__step__body">
      <div class="assessment-plans__step__body__actions step2__filters">
        <mt-filters
            @change="saveColsInCache()"
            v-model:cols="cols"
            :search-visibility="false"
            :filter-visibility="false"
            :sort-visibility="false"
        />
      </div>

      <div class="step2__body">
        <info-block
            v-if="tasks.length === 0"
            title="Нет заданий. Пожалуйста, вернитесь в шаг 1, и добавьте новые задания."
        />
        <mt-expander-item
            v-for="task in computedTasks"
            :key="task.name"
            :state="false"
        >
          <template #header>
            <div class="step2__table__task-name">
              <task-icon
                  :type="task.type"
                  class="step2__table__task-name__icon"
              />

              <div class="step2__table__task-name__desc">
                <div class="step2__table__task-name__title">{{ task.name }}</div>
                <div class="step2__table__task-name__assessment">
                  Тип оценки:

                  <span class="step2__table__task-name__assessment__value">
                      {{ assessmentTypes[task.parameters.param_type] }}
                    </span>
                </div>
              </div>

            </div>

            <div class="step2__table__task-additional-info">
              <div class="step2__table__task-additional-info__label">
                {{ task.type === TaskType.TEST || task.type === TaskType.TEST_PRO ? 'Выбрано шкал:' : 'Кол-во шкал:' }}
              </div>
              <span class="step2__table__task-additional-info__value">
                  <app-placeholder
                      v-if="task.isLoading"
                      height="20px"
                      width="50px"
                  />
                  <template v-else>
                    {{ task.selectedScalesLength }}
                    <template v-if="task.type === 'test' || task.type === 'test_pro'">
                      /
                      {{ task.scales?.length }}
                    </template>
                  </template>

                </span>
            </div>

            <div class="step2__table__errors">
              <second-step-tasks-error :scales="task.erroredScales" />
            </div>

            <mt-button
                color="light-gray"
                icon
                class="step2__table__clone-btn"
                :loading="cloningTask === task.uuid"
                @click.stop="cloneTask(task)"
            >
              <clone-icon />
            </mt-button>
          </template>
          <template #content>
            <mt-switch
                hide-details
                class="mb-2"
                v-model="task.allScalesHaveTaskInfo"
                @change="(value) => massToggle(value, task)"
            >
              <template #label>
                Включить все шкалы
              </template>
            </mt-switch>
            <fieldset
                v-for="(block, blockIndex) in task.blocks"
                :class="[
                    'step2__table__fieldset',
                    {
                      'step2__table__fieldset_empty': !blockIndex
                    }
                  ]"
            >
              <legend class="step2__table__fieldset__legend">{{ blockIndex }}</legend>
              <mt-table>
                <template #header>
                  <tr>
                    <th v-if="task.type !== 'test' && task.type !== 'test_pro'" />
                    <th class="step2__table__scale-wrapper">
                      Шкала, критерий, задача
                    </th>

                    <th v-if="task.type === 'test' || task.type === 'test_pro'">
                      <div class="step2__table__switcher-wrapper" />
                    </th>

                    <th v-show="getColumnVisibility('unit')">
                      Ед. шкалы
                    </th>

                    <th v-show="getColumnVisibility('limit')">
                      <div class="step2__table__cell-with-info">
                        Порог
                        <app-tooltip>
                          <template #activator>
                            <info-icon class="step2__table__cell-with-info__icon" />
                          </template>
                          значение в баллах, которое позволяет данное задание считать пройденным
                        </app-tooltip>
                      </div>
                    </th>

                    <th v-show="getColumnVisibility('min')">
                      Мин.
                    </th>

                    <th v-show="getColumnVisibility('max')">
                      Макс.
                    </th>

                    <th v-show="getColumnVisibility('step')">
                      Шаг
                    </th>

                    <th v-show="getColumnVisibility('weight')">
                      <div class="step2__table__cell-with-info">
                        Вес
                        <app-tooltip>
                          <template #activator>
                            <info-icon class="step2__table__cell-with-info__icon" />
                          </template>
                          множитель вклада шкалы в итоговый балл
                        </app-tooltip>
                      </div>
                    </th>

                    <th v-if="task.type === 'expert'" />
                  </tr>
                </template>

                <template #table-insert>
                  <draggable
                      :component-data="{
                        tag: 'tbody',
                        type: 'transition-group',
                        name: !drag ? 'flip-list' : null
                      }"
                      tag="tbody"
                      class="tasks__body"
                      :list="block"
                      v-bind="dragOptions"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="commonInfo.id"
                      handle=".js-drag"
                      @change="({moved}) => checkChange(task, moved)"
                  >
                    <template #item="{ element: scale }">
                      <tr
                          :class="[
                              'step2__table__row',
                              {
                                'step2__table__row_expert': task.type === 'expert',
                                'step2__table__row_confirmation': task.type === 'confirmation',
                              }
                          ]"
                      >
                        <td
                            v-if="task.type !== 'test' && task.type !== 'test_pro'"
                            class="step2__table__scale__handle"
                        >
                          <button class="js-drag tasks__item__buttons__drag-btn">
                            <drag-icon />
                          </button>
                        </td>
                        <td class="step2__table__scale-wrapper">
                          <div class="step2__table__scale-wrapper_value">
                            <input
                                v-if="task.type === 'expert' || task.type === 'confirmation'"
                                :class="[
                                'step2__table__custom-scale-input',
                                {
                                  invalid: !!scale.errors.title,
                                }
                            ]"
                                v-model="scale.commonInfo.title"
                            />
                            <template v-else>
                              {{ scale.commonInfo.title }}
                            </template>
                            <second-step-cell-error
                                :errors="scale.errors?.title"
                            />
                          </div>
                        </td>
                        <td v-if="task.type === TaskType.TEST || task.type === TaskType.TEST_PRO">
                          <div class="step2__table__switcher-wrapper">
                            <mt-switch
                                hide-details
                                @change="toggleScaleOfTest(scale, task)"
                                v-model="scale.hasTaskInfo"
                            />
                          </div>

                        </td>
                        <td v-show="getColumnVisibility('unit')">
                          <template v-if="scale.taskInfo">
                            {{ units[scale.taskInfo.unit] }}
                          </template>

                        </td>
                        <td v-show="getColumnVisibility('limit')">
                          <app-amount-input
                              v-if="scale.taskInfo"
                              :class="[
                            'cell__input',
                            {
                              invalid: !!scale.errors.limit,
                            }
                          ]"
                              :value="scale.taskInfo.limit"
                              :input="(event) => setCriterionParam('limit', scale.taskInfo, event)"
                          />
                          <second-step-cell-error
                              :errors="scale.errors?.limit"
                          />

                        </td>
                        <td v-show="getColumnVisibility('min')">
                          <app-amount-input
                              v-model="scale.taskInfo.min"
                              v-if="scale.taskInfo"
                              :class="[
                            'cell__input',
                            {
                              invalid: !!scale.errors.min,
                              disabled: (task.type === TaskType.TEST || task.type === TaskType.TEST_PRO) && !scale.normalize,
                            }
                          ]"
                              :disabled="(task.type === TaskType.TEST || task.type === TaskType.TEST_PRO) && !scale.taskInfo.normalize"
                              :value="scale.taskInfo.min"
                              :input="(event) => setCriterionParam(min, scale.taskInfo, event)"
                          />
                          <second-step-cell-error
                              :errors="scale.errors?.min"
                          />
                        </td>
                        <td v-show="getColumnVisibility('max')">
                          <app-amount-input
                              v-if="scale.taskInfo"
                              :class="[
                            'cell__input',
                            {
                              invalid: !!scale.errors.max,
                              disabled: (task.type === TaskType.TEST || task.type === TaskType.TEST_PRO) && !scale.normalize,
                            }
                          ]"
                              :disabled="(task.type === TaskType.TEST || task.type === TaskType.TEST_PRO) && !scale.taskInfo.normalize"
                              :value="scale.taskInfo.max"
                              :input="(event) => setCriterionParam('max', scale.taskInfo, event)"
                          />
                          <second-step-cell-error
                              :errors="scale.errors?.max"
                          />
                        </td>
                        <td v-show="getColumnVisibility('step')">
                          <app-amount-input
                              v-if="scale.taskInfo"
                              :class="[
                              'cell__input',
                              {
                                invalid: !!scale.errors.step,
                                disabled: task.type === TaskType.TEST || task.type === TaskType.TEST_PRO
                              }
                            ]"
                              :disabled="task.type === TaskType.TEST || task.type === TaskType.TEST_PRO"
                              :value="scale.taskInfo.step"
                              :input="(event) => setCriterionParam('step', scale.taskInfo, event)"
                          />
                          <second-step-cell-error :errors="scale.errors?.step" />
                        </td>
                        <td v-show="getColumnVisibility('weight')">
                          <app-amount-input
                              v-if="scale.taskInfo"
                              class="cell__input"
                              :class="[
                            'cell__input',
                            {
                              invalid: !!scale.errors.weight,
                              disabled: task.type === 'test' || task.type === 'test_pro'
                            }
                          ]"
                              :value="scale.taskInfo.weight"
                              :input="(event) => setCriterionParam('weight', scale.taskInfo, event)"
                          />
                          <second-step-cell-error
                              :errors="scale.errors?.weight"
                          />
                        </td>
                        <td v-show="task.type === 'expert'">
                          <mt-button
                              icon
                              @click="deleteScaleOfExpertReview(scale, task)"
                              color="light-red"
                          >
                            <trash-icon />
                          </mt-button>
                        </td>
                      </tr>
                    </template>
                  </draggable>
                  <tr v-if="block.length === 0 && !task.isLoading && !task.isError">
                    <td colspan="9">
                      <info-block />
                    </td>
                  </tr>

                  <tr v-if="task.type === TaskType.EXPERT">
                    <td></td>
                    <td>
                      <app-input
                          v-model="task.newCustomScaleName"
                          @keyup.enter="addScaleToExpertReview(task)"
                          class="create-custom-scale__input"
                          label="Новая шкала"
                      />
                    </td>
                    <td colspan="7">
                      <mt-button
                          @click="addScaleToExpertReview(task)"
                          class="create-custom-scale__button"
                      >
                        Добавить шкалу
                      </mt-button>
                    </td>

                  </tr>

                  <template v-if="task.isLoading">
                    <tr v-for="index in 5">
                      <td v-for="i in 9">
                        <app-placeholder
                            class="mt-data-table__placeholder"
                            height="32px"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
              </mt-table>
            </fieldset>

            <app-alert
                v-if="task.isError"
                type="error"
                class="mt-4"
            >
              Непредвиденная ошибка при загрузке данных о шкалах, пожалуйста повторите позже
            </app-alert>
          </template>
        </mt-expander-item>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import mtPageNav from '@/components/composite/mtPageNav.vue';
import mtFilters from '@/components/UI/mtFilters/mtFilters.vue';
import {isEmpty, deepClone, groupBy} from "@/services/utils";
import mtButton from "@/components/UI/mtButton/index.vue";
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import AppCheckbox from "@/components/UI/AppCheckbox/AppCheckbox.vue";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import MtSvg from "@/components/UI/mtSvg/index.vue";
import TaskIcon from "@/components/workspace/assessmentPlans/plans/TaskIcon.vue";
import MtExpander from "@/components/UI/mtExpander/mtExpander.vue";
import MtExpanderItem from "@/components/UI/mtExpander/mtExpanderItem.vue";
import MtTable from "@/components/UI/mtTable/mtTable.vue";
import mtSwitch from '@/components/UI/mtSwitch/index.vue'
import TasksAdminApi from "@/api/tasks-admin.api";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import InfoBlock from "@/components/workspace/InfoBlock.vue";
import AppAmountInput from "@/components/UI/AppAmountInput/AppAmountInput.vue";
import AppPlaceholder from "@/components/UI/AppPlaceholder/AppPlaceholder.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import AppAlert from "@/components/UI/AppAlert/AppAlert.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import AlertCircleIcon from "@/components/icons/AlertCircleIcon.vue";
import SecondStepCellError from "@/components/workspace/assessmentPlans/plans/plan/step2/SecondStepCellError.vue";
import SecondStepTasksError from "@/components/workspace/assessmentPlans/plans/plan/step2/SecondStepTasksError.vue";
import Step2ColsCache from "@/cache/step2-cols.cache";
import TaskType from "../../../../enums/task-type.enum";
import draggable from "vuedraggable";
import DragIcon from "@/components/icons/DragIcon.vue";
import axios from "axios";
import CloneIcon from "@/components/icons/CloneIcon.vue";
import tasksAdminApi from "@/api/tasks-admin.api";

export default {
  components: {
    CloneIcon,
    DragIcon,
    draggable,
    SecondStepTasksError,
    SecondStepCellError,
    AlertCircleIcon,
    InfoIcon,
    AppTooltip,
    PlusIcon,
    AppAlert,
    TrashIcon,
    SettingsIcon,
    AppPlaceholder,
    AppAmountInput,
    InfoBlock,
    AppInput,
    MtTable,
    MtExpanderItem,
    MtExpander,
    TaskIcon,
    MtSvg,
    MtDialog,
    AppCheckbox,
    AppSelect,
    mtButton,
    mtPageNav,
    mtFilters,
    mtSwitch
  },
  data() {
    return {
      nextRequestPending: false,
      cols: [
        {
          title: 'Ед. шкалы',
          stub: 'unit',
          showable: true,
          visible: true,
        },
        {
          title: 'Порог',
          stub: 'limit',
          showable: true,
          visible: false,
        },
        {
          title: 'Минимум',
          stub: 'min',
          showable: true,
          visible: true,
        },
        {
          title: 'Максимум',
          stub: 'max',
          showable: true,
          visible: true,
        },
        {
          title: 'Шаг',
          stub: 'step',
          showable: true,
          visible: false,
        },
        {
          title: 'Вес, множитель',
          stub: 'weight',
          showable: true,
          visible: false,
        },
      ],
      tasks: [],
      units: {
        stan: "Стэн-баллы",
        point: "Балл",
        percent: "Процент",
        percentile: "Процентиль",
      },
      assessmentTypes: {
        auto: "автоматическая",
        expert: "экспертная",
        confirmation: "подтверждение",
      },
      drag: false,
      cloningTask: null
    };
  },
  inject: ['getPlan'],
  computed: {
    TaskType() {
      return TaskType
    },
    ...mapGetters('assessmentPlans/plan', ['planEditdataExd']),

    computedTasks() {
      return this.tasks.map(task => {
        Object.values(task.blocks)
            .forEach((blockScales) => {
              for (let scale of blockScales) {
                scale.errors = {};
                scale.hasTaskInfo = !!scale.taskInfo;

                if (scale.taskInfo) {
                  // check if every field is filled one by one
                  if (isEmpty(scale.taskInfo.limit)) {
                    scale.errors['limit'] = [
                      'Порог не может быть пустым'
                    ]
                  }

                  if (isEmpty(scale.taskInfo.min)) {
                    scale.errors['min'] = [
                      'Минимальное значение не может быть пустым'
                    ]
                  }

                  if (isEmpty(scale.taskInfo.max)) {
                    scale.errors['max'] = [
                      'Максимальное значение не может быть пустым'
                    ]
                  }

                  if (isEmpty(scale.taskInfo.step)) {
                    scale.errors['step'] = [
                      'Шаг не может быть пустым'
                    ]
                  }

                  if (isEmpty(scale.taskInfo.weight)) {
                    scale.errors['weight'] = [
                      'Вес не может быть пустым'
                    ]
                  }

                  //check if limit is greater than max
                  if (scale.taskInfo.limit > scale.taskInfo.max) {
                    scale.errors['limit'] = [
                      'Порог не может быть больше максимального значения'
                    ]
                  }
                  // check if min is greater than max
                  if (scale.taskInfo.min > scale.taskInfo.max) {
                    scale.errors['min'] = [
                      'Минимальное значение не может быть больше максимального'
                    ]
                  }

                  // check if step is greater than max
                  if (scale.taskInfo.step > scale.taskInfo.max) {
                    scale.errors['step'] = [
                      'Шаг не может быть больше максимального значения'
                    ]
                  }

                  if (scale.taskInfo.step <= 0) {
                    scale.errors['step'] = [
                      'Шаг не может быть меньше или равен нулю'
                    ]
                  }

                  // check for title is not empty
                  if (!scale.commonInfo.title) {
                    scale.errors['title'] = [
                      'Название шкалы не может быть пустым'
                    ]
                  }

                  // check for title duplicate in scales
                  if (task.type === 'expert') {
                    const isTitleDuplicate = task.blocks['']
                        .filter(item => item.commonInfo.title.toLowerCase() === scale.commonInfo.title.toLowerCase() && item.hasTaskInfo)
                        .length > 1;
                    if (isTitleDuplicate) {
                      scale.errors['title'] = [
                        'Шкала с таким названием уже существует'
                      ]
                    }
                  }
                }
              }
            })
        const scales = Object.values(task.blocks).flat();

        task.allScalesHaveTaskInfo = scales.every(scale => scale.taskInfo);
        task.erroredScales = scales.filter(scale => {
          return Object.keys(scale.errors).length;
        });

        task.selectedScalesLength = scales.flat().filter(scale => scale.taskInfo).length;

        return task;
      });
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['setPlanStore', 'fetchPlan']),
    saveColsInCache() {
      const selectedCols = this.cols.filter(col => col.visible).map(col => col.stub);
      Step2ColsCache.set(JSON.stringify(selectedCols));
    },
    getColumnVisibility(stub) {
      return this.cols.find(col => col.stub === stub)?.visible;
    },
    massToggle(value, task) {
      const isAllScalesHaveTaskInfo = task.scales.every(scale => scale.taskInfo);
      if (!isAllScalesHaveTaskInfo) {
        task.scales.forEach(scale => {
          if (!scale.taskInfo) {
            this.addScaleToTest(scale);
          }
        });
      } else {
        task.scales.forEach(scale => {
          this.deleteScaleOfTest(scale)
        });
      }
    },
    addScaleToExpertReview(task) {
      if (!task.newCustomScaleName) {
        this.$toast.error('Название шкалы не может быть пустым');
        return;
      }

      if (task.scales.some(scale => {
        const sameName = scale.commonInfo.title.toLowerCase() === task.newCustomScaleName.toLowerCase();
        const hasTaskInfo = scale.taskInfo;
        return sameName && hasTaskInfo;
      })) {
        this.$toast.error('Шкала с таким названием уже существует');
        return;
      }

      TasksAdminApi.createScale(
          this.$route.params.planUuid,
          task.uuid,
          {
            title: task.newCustomScaleName
          }
      ).then(({data}) => {
        task.newCustomScaleName = '';
        task.blocks[''].push({
          commonInfo: data.scale,
          taskInfo: {
            ...data,
            unit: task.type === 'expert' ? "point" : "stan",
            limit: 0,
            max: 10,
            min: 1,
            weight: 1,
            step: 1
          },
          oldTaskInfo: null,
          errors: null
        });
      })
    },
    deleteScaleOfExpertReview(scale, task) {
      task.blocks[''] = task.blocks[''].filter(item => item.commonInfo.title !== scale.commonInfo.title);
      task.scales = task.scales.filter(item => item !== scale);
    },
    setCriterionParam(key, scaleTaskInfo, event = null) {
      scaleTaskInfo[key] = event?.target?.value;
    },
    addScaleToTest(scale) {
      scale.taskInfo = scale.oldTaskInfo ?? {
        limit: 0,
        max: 10,
        min: 1,
        normalize: false,
        step: 1,
        unit: 'stan',
        weight: 1,
        normalize_scales: [],
      }
    },
    deleteScaleOfTest(scale) {
      scale.oldTaskInfo = deepClone(scale.taskInfo);
      scale.taskInfo = null;
    },
    toggleScaleOfTest(scale) {
      if (scale.taskInfo) {
        this.deleteScaleOfTest(scale);
      } else {
        this.addScaleToTest(scale);
      }
    },

    back() {
      this.$router.push({
        name: 'AddEdit1',
      });
    },
    async next() {
      const hasValidationErrors = this.computedTasks.some(task => task.erroredScales.length);

      if (hasValidationErrors) {
        return this.$toast.error('Вы ввели невалидные данные. Пожалуйста, исправьте их и попробуйте снова.');
      }

      this.nextRequestPending = true;

      Promise.all(this.computedTasks.map(task => {
        if (task.isLoading) return;

        const scales = Object.values(task.blocks)
            .flat()
            .filter(scale => scale.taskInfo)
            .map((scale, index) => {
              return {
                scale: scale.commonInfo,
                ...scale.taskInfo,
                order: index
              }
            });

        return TasksAdminApi.updateScales(
            this.$route.params.planUuid,
            task.uuid,
            scales
        )
      }))
          .then(async (response) => {
            await this.fetchPlan({uuid: this.$route.params.planUuid});

            this.$router.push({
              name: 'AddEdit3',
            });
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error('Непредвиденная ошибка при сохранении данных');
            throw error;
          })
          .finally(() => {
            this.nextRequestPending = false;
          });
    },

    async getTestScales() {
      const testTasks = this.tasks?.filter(task => task.type === 'test' || task.type === 'test_pro') ?? []
      const expertTasks = this.tasks?.filter(task => task.type === 'expert') ?? []

      expertTasks.forEach(task => {
        task.scales = task.parameters.criteria.map(item => {
          return {
            commonInfo: item.scale,
            taskInfo: item,
            oldTaskInfo: null,
            errors: null
          }
        });
        task.blocks = {
          '': task.scales
        }
      });
      for (let task of testTasks) {
        TasksAdminApi
            .getTestScales(this.$route.params.planUuid, task.uuid)
            .then((response) => {
              task.scales = response.data.data.map(item => {
                const scaleInTask = task.parameters.criteria.find(criteria => criteria.scale?.uuid === item?.uuid);
                return {
                  commonInfo: {
                    ...item,
                    block: item.block ?? '' // sometimes backend for some reason returns null instead of '' block
                  },
                  taskInfo: scaleInTask || null,
                  oldTaskInfo: null,
                  errors: null,
                }
              })
                  .sort((a, b) => a.taskInfo?.order - b.taskInfo?.order);
              task.blocks = groupBy(task.scales, (item) => item.commonInfo.block);

            })
            .catch((error) => {
              task.isError = true;
              Promise.reject(error);
            })
            .finally(() => {
              task.isLoading = false;
            });
      }
    },

    checkCols() {
      const colsCache = Step2ColsCache.getJsonified();
      if (colsCache.length === 0) return;

      this.cols = this.cols.map(col => {
        col.visible = colsCache.includes(col.stub);
        return col;
      });
    },

    checkChange(task, moved) {
      if (task?.uuid && moved) {
        this.updateScaleOrder(task.uuid, moved.element.taskInfo.id, moved.newIndex);
      }
    },

    async updateScaleOrder(taskUuid, scaleUuid, newOrder) {
      await axios.put(`/plans/${this.$route.params.planUuid}/tests/${taskUuid}/scales/${scaleUuid}/order`, {
        order: newOrder
      });
    },

    async cloneTask(task) {
      this.cloningTask = task.uuid;
      await tasksAdminApi.clone(this.$route.params.planUuid, task.uuid);
      await this.getPlan()
          .then(() => {
            this.tasks = this.planEditdataExd?.structure?.map(item => {
              return {
                ...item,
                search: '',
                scales: [],
                blocks: {},
                isLoading: item.type === 'test' || item.type === 'test_pro',
                isError: false,
                newCustomScaleName: '',
              }
            });

            this.getTestScales();
          });
      this.cloningTask = null;
    }
  },
  async created() {
    this.checkCols();

    this.tasks = this.planEditdataExd?.structure?.map(item => {
      return {
        ...item,
        search: '',
        scales: [],
        blocks: {},
        isLoading: item.type === 'test' || item.type === 'test_pro',
        isError: false,
        newCustomScaleName: '',
      }
    });

    await this.getTestScales();
  }
};
</script>

<style lang="scss">
.cell__input {
  background-color: #F0F1F7;
  border-radius: 6px;
  text-align: center;
  width: 45px;
  height: 24px;
  font-size: 12px;
  border: 1px solid transparent;

  &:disabled {
    opacity: 0.5;
  }
}

.step2 {
  .mt-table {
    overflow: visible;
    border: none;
  }

  .mt-table__wrapper {
    overflow: visible;
  }

  &__table {
    &__fieldset {
      border: 1px solid #DFE0EB;
      margin: 0 2px 30px;
      padding: 0;
      overflow: hidden;
      border-radius: 12px;

      tr:last-child td {
        border-bottom: none;
      }

      &__legend {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        color: $dark;
        letter-spacing: 0.5px;
        margin-left: 30px;
      }

      &_empty {
        padding: 0;
        overflow: hidden;

        legend {
          display: none;
        }
      }
    }

    &__cell-with-info {
      display: flex;
      align-items: center;

      &__icon {
        color: $green;
        margin-left: 4px;
        width: 16px;
        height: 16px;
      }

      .tooltip__body {
        width: 200px;
      }
    }

    &__custom-scale-input {
      background-color: #F0F1F7;
      border-radius: 6px;
      text-align: left;
      width: calc(100% - 24px);
      padding-left: 8px;
      height: 24px;
      border: 1px solid transparent;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.5px;
    }

    &__scale-wrapper {
      width: 200px;

      &_value {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.5px;
      }
    }

    &__scale__handle {
      width: 30px;
      padding-right: 0 !important;

      .tasks__item__buttons__drag-btn {
        margin-right: 0;
      }
    }

    &__switcher-wrapper {
      width: 56px;
      height: 30px;

      display: flex;
      align-items: center;
    }
  }

  .mt-table {
    padding: 0;
  }

  .mt-table__table td, .mt-table__table th {
    font-size: 12px;
    line-height: 1.1;
    padding: 10px 15px;
    position: relative;
  }

  .expander__item__title {
    display: flex;
    align-items: center;
  }

  &__filters {
    margin-bottom: 10px;
  }

  &__body {
    width: 100%;

    .expander__item__arrow-btn {
      margin-left: auto !important;
      margin-right: 0 !important;
    }

    .expander__item__body {
      padding: 15px;
    }

    .expander__item__header {
      padding: 0 15px;
      background-color: #F0F1F7;
      border-radius: 10px;
      height: 64px;
      width: 100%;
      transition: background-color 0.3s;

      &:hover {
        background-color: #e8e9f3;
      }
    }

    .expander__item__title {
      flex-grow: 1;
    }
  }
}

.invalid {
  border: 1px solid $red;
  background-color: $light-red;
  color: $red;
}

.step2__table {
  width: 100%;

  &__task-additional-info {
    font-size: 14px;
    margin-left: 30px;

    &__label {
      margin-bottom: 4px;
      color: $dark;
    }
  }

  &__errors {
    margin-left: 32px;
  }

  &__clone-btn {
    margin-left: auto;
    margin-right: 10px;
  }

  &__task-name {
    display: flex;
    align-items: center;
    width: 400px;

    &__icon {
      margin-right: 15px;
    }

    &__title {
      font-size: 16px;
    }

    &__assessment {
      margin-top: 4px;
      font-size: 12px;
      color: #9fa2b4;

      &__value {
        color: #252733;
        margin-left: 5px;
      }
    }
  }

  &__criterion {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  &__criteria-select {
    width: 240px;
    margin-bottom: 0;

    .checkbox {
      pointer-events: none;
      margin-right: 15px;
    }
  }
}

.create-custom-scale {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #F0F1F7;
  border-radius: 8px;
  width: 100%;

  &__input {
    width: calc(100% - 24px);
    margin-bottom: 0;
    height: 32px;

    .app-form-block__label {
      top: 8px;
    }

    .app-input__field {
      height: 32px;
      font-weight: 600;
    }
  }

  &__button {
    flex-shrink: 0;
    height: 32px;
  }
}
</style>