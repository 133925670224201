import api from "@/network";
import { generateId, } from '@/services/Mathe';
import { taskSingleAdapter } from '@/adapters/assessmentPlans/tasks/tasks.adapter';
import { makeMarkItem, scaleIndexAdapter } from '@/adapters/assessmentPlans/tasks/customAssessment.adapter';
import useToast from "@/components/UI/AppToast/useToast";
import CustomAssessmentApi from "@/api/custom-assessment.api";

const fetchBasicTask = async (payload) => {
    return await api.get(`plans/${ payload.uuidPlan }/tests/${ payload.uuid }`);
}

export default {
    state: {
        mark: null,
        sessionUuid: '',
        expRespTask: null,
        task: {
            parameters: {
                param_type: ''
            },
            files: [],
            files_expert: [],
            mainTest: {
                TestTitle: ''
            }
        },
        messages: [],
        scales: [],
        marksChanged: false
    },

    getters: {
        task(state) {
            return state.task;
        }
    },

    actions: {
        async getMark({commit}, {planUuid, taskUuid, sessionUuid}) {
            return CustomAssessmentApi.getMark(planUuid, taskUuid, sessionUuid)
                .then((response) => {
                    commit('updateMark', makeMarkItem(response));
                })
                .catch((error) => {
                    commit('updateMark', null);
                })
        },

        async getUserSession({commit}, {planUuid, taskUuid, respondentUuid}) {
            return await CustomAssessmentApi.getRespondentSession(planUuid, taskUuid, respondentUuid)
                .then((response) => {
                    commit('updateSessionUuid', response.data);
                });
        },

        /* FETCHERS */
        async getScales({commit}, {planUuid, taskUuid, sessionUuid}) {
            return await CustomAssessmentApi.getScales(planUuid, taskUuid, sessionUuid)
                .then((response) => {
                    commit('updateScales', response.data.data);
                });
        },
        async fetchExpRespTask({commit}, payload = {}) {
            const response = await fetchBasicTask(payload);
            const data = taskSingleAdapter(response.data.data, payload.isMock);
            commit('updateExpRespTask', data);
        },

        async fetchTask({commit}, payload = {}) {
            const response = await fetchBasicTask(payload);
            commit('updateStore', {
                entity: 'task',
                payload: response.data.data,
            });
            return response;
        },

        async startTaskSession({}, {planUuid, taskUuid}) {
            return await api.post(`plans/${planUuid}/tests/${taskUuid}/session`);
        },
        async startCustomAssessmentSession({commit}, {planUuid, taskUuid}) {
            return await CustomAssessmentApi.startSession(planUuid, taskUuid)
                .then((uuid) => {
                    commit('updateSessionUuid', uuid);
                });
        },
        async confirmTask({commit}, {planUuid, test}) {
            return await api.post(`plans/${planUuid}/tests/${test.uuid}/confirm`)
                .then(() => {
                    commit('assessmentPlans/plan/moveTestToFinished', test, {root: true});
                });
        },
        async updateTask({ commit }, payload = {}) {
            return await api.put(`plans/${ payload.uuidPlan }/tests/${ payload.uuidTest }`, payload.data);
        },
        setTask({commit}, payload = {}) {
            commit('setTask', payload)
        }
    },

    mutations: {
        updateScales(state, scales) {
            state.scales = scales;
        },
        updateMark(state, newMark) {
            if(!newMark) {
                state.mark = null
            } else {
                newMark.scales.forEach(scale => {
                    let foundScale = state.scales.find(item => scale.name === item.name);
                    if (!foundScale) return;

                    foundScale.value = scale.value;

                });
            }
            state.mark = newMark;

        },
        updateSessionUuid(state, sessionId) {
            state.sessionUuid = sessionId;
        },
        updateExpRespTask(state, payload) {
            state.expRespTask = payload;
            state.expRespTaskLoaded = true;
        },
        updateStore(state, { entity, payload }) {
            state[entity] = payload;
        },
        setTask(state, payload) {
            if (!payload.files) payload.files = []
            if (!payload.files_expert) payload.files_expert = []
            state.task = {
                ...state.task,
                ...payload
            }
        },
        updateMarksChanged(state, value) {
            state.marksChanged = value;
        },
        setChatMessages(state, value) {
            state.messages = value;
        }
    },
}