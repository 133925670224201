import api from "@/network";
import { defineStore } from "pinia";
import { useStore as useVuexStore } from 'vuex';
import customAssessmentApi from "@/api/custom-assessment.api.ts";
import CustomAssessmentApi from "@/api/custom-assessment.api.ts";

export type SetMarkParams = {
    respondentUuid: string;
    planUuid: string;
    taskUuid: string;
    sessionUuid: string;
    scale: any;
}

const respondents = ref([]);
const isRespondentsLoading = ref(false);
const vuexStore = ref(null);

const useRespondentsStore = defineStore('respondents-store', () => {
    vuexStore.value = useVuexStore();

    async function getRespondents(planUuid: string, params?: any) {
        const expertPath = `plans/${planUuid}/expert/respondents`;
        const otherPath = `plans/${planUuid}/respondents`;
        const isExpert = ['expert', 'inspector'].includes(vuexStore.value.getters.authUserRoleSlug);

        const path = isExpert  ? expertPath : otherPath;

        isRespondentsLoading.value = true;
        return await api.get(path, { params })
            .then(({data}) => {
                respondents.value = data.data.map(respondent => {
                    respondent.is_calculating = respondent.tests.some(test => test.is_calculating);
                    return respondent;
                });
                return Promise.resolve(data);
            })
            .finally(() => {
                isRespondentsLoading.value = false;
            });
    }

    async function getRespondentSession(planUuid: string, taskUuid: string, respondentUuid: string) {
        return await customAssessmentApi.getRespondentSession(planUuid, taskUuid, respondentUuid);
    }

    async function setMarkToRespondentTaskScale(params: SetMarkParams) {
        let sessionUuid = params.sessionUuid;

        if(!sessionUuid) {
            const {data} = await getRespondentSession(params.planUuid, params.taskUuid, params.respondentUuid);
            sessionUuid = data;
        }

        await CustomAssessmentApi.setMark(
            params.planUuid,
            params.taskUuid,
            sessionUuid,
            [
                {
                    uuid: params.scale.uuid,
                    grade: params.scale.current
                }
            ],
        ).then((result) => {
            const respondent = respondents.value.find(respondent => respondent.uuid === params.respondentUuid);
            const task = respondent.tests.find(test => test.uuid === params.taskUuid);

            respondent.balls = result.balls;
            respondent.grade = result.grade;
            task.current = result.current;
            task.current_with_weight = result.current_with_weight;
            task.completed = true;

            result.scales.forEach(resultScale => {
                const taskScale = task.scales.find(scale => scale.uuid === resultScale.uuid);
                taskScale.current = resultScale.current;
                taskScale.current_with_weight = resultScale.current_with_weight;
            })
        })
    }

    return {
        getRespondents,
        isRespondentsLoading,
        respondents,
        setMarkToRespondentTaskScale,
        getRespondentSession
    }
})

export default useRespondentsStore;