<template>
  <component :is="planComponent"/>
</template>

<script>
import planAdmin from "@/components/workspace/assessmentPlans/plans/plan/AdminPlanSingle.vue";
import planExpert from "@/components/workspace/assessmentPlans/plans/plan/ExpertPlanSingle.vue";
import planRespondent from "@/components/workspace/assessmentPlans/plans/plan/RespondentPlanSingle.vue";
import planDefault from "@/components/workspace/assessmentPlans/plans/plan/DefaultPlanSingle.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    planRespondent,
    planExpert,
    planAdmin,
    planDefault
  },
  computed: {
    ...mapGetters(['authUserRoleSlug']),

    planComponent() {
      switch (this.authUserRoleSlug) {
        case 'expert':
        case 'inspector':
          return 'planExpert';

        case 'respondent':
          return 'planRespondent';

        case 'admin':
          return 'planAdmin';

        default:
          return 'planDefault';
      }
    }
  }
}
</script>