<script
    setup
    lang="ts"
>
const props = withDefaults(defineProps<{
  file: any;
  nameKey?: string;
  sizeKey?: string;
  typeKey?: string;
  urlKey?: string;
  bgColor?: string;
  deleteFn?: () => Promise<any>;
}>(), {
  nameKey: 'file_name',
  sizeKey: 'size',
  urlKey: 'original_url',
  bgColor: '#f5f5f5',
});

const size = computed(() => {
  const sizeInBytes = props.file[props.sizeKey];
  const sizeInKb = sizeInBytes / 1024;
  const sizeInMb = sizeInKb / 1024;

  if (sizeInMb > 1) {
    return `${sizeInMb.toFixed(2)} MB`;
  }
  if (sizeInKb > 1) {
    return `${sizeInKb.toFixed(2)} KB`;
  }
  return `${sizeInBytes} B`;
});
</script>

<template>
  <component
      :is="file[props.urlKey] ? 'a' : 'div'"
      :href="file[props.urlKey]"
      download
      target="_blank"
      class="uploaded-file"
  >
    <slot name="prepend" />

    <div class="uploaded-file__content">
      <div class="uploaded-file__name">
      <span
          class="uploaded-file__name__text"
          :title="file[props.nameKey]"
      >
        {{ file[props.nameKey] }}
      </span>

        <span class="uploaded-file__size">
        ({{ size }})
      </span>
      </div>

      <button
          v-if="props.deleteFn"
          class="uploaded-file__close-btn"
          @click.stop="props.deleteFn()"
      >
        <svg
            data-src="/img/icons/close.svg"
            class="uploaded-file__close-btn__icon"
        />
      </button>
    </div>
  </component>
</template>

<style lang="scss">
.uploaded-file {
  background-color: v-bind(bgColor);
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  gap: 5px;
  color: $gray;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__name {
    color: #000;
    margin-right: 8px;


    &__text {
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__size {
    white-space: nowrap;
    color: $green;
  }

  &__close-btn {
    display: flex;
    width: 10px;
    height: 10px;
    margin-left: auto;

    &__icon {
      width: 100%;
      height: 100%;
      transition: transform .3s;
    }

    &:hover {
      .uploaded-file__close-btn__icon {
        transform: rotate(180deg);
      }
    }
  }
}
</style>