<script setup lang="ts">
const props = withDefaults(defineProps<{
  size: 'small' | '';
}>(), {
  size: '',
});
</script>

<template>
  <div :class="['loader', {
    'loader_small': props.size === 'small'
  }]" />
</template>

<style lang="scss">
.loader {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid $light-green;
  border-right-color: $green;
  animation: l2 1s infinite linear;

  &_small {
    width: 15px;
    border-width: 2px;
  }
}

@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
</style>