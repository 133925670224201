<script
    setup
    lang="ts"
>
import AppSelect from '../AppSelect/AppSelect.vue'
import debounce from "lodash/debounce";

type Props = {
  modelValue: any
  items: any[]
  itemText?: string
  itemValue?: string
  disabled?: boolean
  onlyNumbers?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  itemText: 'label',
  itemValue: 'value',
  modelValue: null,
})

const emit = defineEmits(['update:modelValue', 'change', 'invalid'])

const searchText = ref('')
const selectRef = ref()

const computedModelValue = computed({
  get() {
    return props.modelValue
  },

  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const computedItems = computed(() => {
  if (!searchText.value.length) return props.items

  return props.items.filter((item) => {
    const value = item[props.itemText] as string

    return !!(value && value?.toLowerCase().includes(searchText.value.toLocaleLowerCase()));
  })
})

function onChange() {
  emit('invalid', false)
  emit('change')
}

function clearSearch() {
  searchText.value = ''
}

function onEnter() {
  if(searchText.value) {
    const foundItem = computedItems.value.find(item => item[props.itemText] === searchText.value)
    if(foundItem) {
      computedModelValue.value = foundItem[props.itemValue]
      selectRef.value.deactivateOptions()
    }
  }
}

function onInput(event: InputEvent) {
  if (!event.target || !props.onlyNumbers) return;

  const input = event.target as HTMLInputElement;
  let value = input.value;

  value = value.replace(/[^0-9]/g, '');

  input.value = value;
  searchText.value = value;
}

function checkInputValidity() {
  if(searchText.value === '') {
    emit('invalid', false);
    return null;
  }
  const item = props.items?.find(item => item[props.itemText] === searchText.value);
  emit('invalid', !item);

  return item;
}

function checkValueValidity() {
  if(computedModelValue.value === '') {
    emit('invalid', false);
    return null;
  }
  const item = props.items?.find(item => item[props.itemValue] === computedModelValue.value);
  emit('invalid', !item);

  return item;
}

defineExpose({
  clearSearch
})

onMounted(() => {
  if(computedModelValue.value !== undefined && computedModelValue.value !== null) {
    const item = checkValueValidity();
    searchText.value = item ? item[props.itemText] : searchText.value;
  }

  nextTick(() => {
    watch(
        [() => computedModelValue.value, () => props.items],
        () => {
          if (computedModelValue.value && props.items.length) {
            const item = checkValueValidity();

            if(item) searchText.value = item[props.itemText]
            else {
              searchText.value = computedModelValue.value
            }
          }
        }
    )
  })
})

const debouncedWatch = debounce(() => {
  const item = checkInputValidity();

  if (item) {
    computedModelValue.value = item[props.itemValue];
  }
  else {
    computedModelValue.value = searchText.value;
  }
}, 500); // 500 мс задержка

onBeforeUnmount(() => {
  debouncedWatch.cancel()
})
</script>

<template>
  <app-select
      ref="selectRef"
      v-bind="$attrs"
      :active="!!searchText?.length ?? false"
      :items="computedItems"
      :item-text="itemText"
      :item-value="itemValue"
      :disabled="disabled"
      :optionsTabDisabed="true"
      v-model="computedModelValue"
      class="app-autocomplete"
      @change="onChange"
  >
    <template #header>
      <input
          v-model="searchText"
          type="text"
          :disabled="disabled"
          class="app-autocomplete__input"
          :inputmode="props.onlyNumbers ? 'numeric' : 'text'"
          @focus="selectRef.activateOptions()"
          @keyup.enter="onEnter"
          @keyup="debouncedWatch"
          @input="onInput"
      />
    </template>
    <template #item="item">
      <slot
          name="item"
          :item="item"
      />
    </template>
  </app-select>
</template>

<style
    lang="scss"
    src="./AppAutocomplete.scss"
/>
