<script
    setup
    lang="ts"
>
import AppRangeSlider from "@/components/UI/AppRangeSlider.vue";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import debounce from "lodash/debounce";
import { computed } from "vue";

const props = defineProps<{
  modelValue: number | undefined;
  data?: number[];
  min?: number;
  max?: number;
  step?: number;
  weight?: number;
  hideDetails?: boolean;
}>();
const emit = defineEmits(['update:modelValue']);

const sliderDebouncer = debounce((value: number) => {
  emit('update:modelValue', value);
}, 500);

const computedModelValue = computed({
  get: () => {
    return (props.modelValue === undefined || props.modelValue < props.min) ? props.min : props.modelValue;
  },
  set: (value: number) => {
    sliderDebouncer(value);
  }
});

/**
 * Корректирует значение с учётом min, max и step.
 */
function adjustToStep(value: number): number {
  const stepMultiplier = 1 / props.step;
  // Округляем вверх для более высоких значений
  const adjustedValue = Math.ceil((value - props.min) * stepMultiplier) / stepMultiplier + props.min;

  // Ограничение значения в пределах min и max
  return Math.min(Math.max(adjustedValue, props.min), props.max);
}

/**
 * Обрабатывает ввод с задержкой (debounce).
 */
const debouncedInputHandler = debounce((value: number, input: HTMLInputElement) => {
  if (input.value === '') return;

  const adjustedValue = adjustToStep(value);
  if (adjustedValue !== props.modelValue) {
    input.value = adjustedValue.toString();
    emit('update:modelValue', adjustedValue);
  }
}, 300);

/**
 * Основной обработчик ввода.
 */
function handleInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let rawValue = input.value;

  // Убираем все символы, кроме цифр и точки
  rawValue = rawValue.replace(/[^0-9.]/g, '');
  input.value = rawValue;

  // Если строка пустая, не запускаем debounce
  if (rawValue === '') {
    debouncedInputHandler.cancel();
    return;
  }

  // Если значение заканчивается на точку и перед точкой есть цифры, оставляем её
  if (rawValue.endsWith('.') && rawValue.length > 1 && !isNaN(Number(rawValue.slice(0, -1)))) {
    input.value = rawValue;
    debouncedInputHandler.cancel();
    return;
  }

  // Проверка на допустимость (не больше одной точки)
  if ((rawValue.match(/\./g) || []).length > 1) {
    return; // Если точка встречается более одного раза, не обрабатываем
  }

  // Конвертируем строку в число
  const numericValue = parseFloat(rawValue);

  // Если значение корректное, вызываем debounced обработчик
  if (!isNaN(numericValue)) {
    debouncedInputHandler(numericValue, input);
  } else {
    // Если введено некорректное число, оставляем исходное значение
    input.value = rawValue;
  }
}
</script>

<template>
  <div class="scale-mark-range">
    <div class="scale-mark-range__details">
      <div
          v-if="!props.hideDetails"
          class="scale-mark-range__desc"
      >
        <p>
          <strong>Шаг:</strong>
          {{ props.step }}
        </p>
        <p>
          <strong>Множитель: </strong>
          {{ props.weight }}
        </p>
      </div>

      <app-input
          :value="props.modelValue"
          small
          hide-details
          class="scale-mark-range__input"
          @input="handleInput"
      />
    </div>

<!--    <app-range-slider-->
<!--        v-model="computedModelValue"-->
<!--        :min="props.min"-->
<!--        :max="props.max"-->
<!--        :step="props.step"-->
<!--        :data="props.data"-->
<!--    />-->

  </div>
</template>

<style lang="scss">
.scale-mark-range {
  padding: 10px 10px 0;

  &__details {
    display: flex;
  }

  &__desc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    font-size: 11px;
    gap: 2.5px;
  }

  &__input {
    width: 50px;

    .app-input__field {
      text-align: center;
      width: 40px;
      height: 30px;
      font-size: 12px;
      padding: 0 4px;

    }
  }
}
</style>