<script setup lang="ts">

import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import AlertCircleIcon from "@/components/icons/AlertCircleIcon.vue";

interface Props {
  scales: any[]
}

const props = withDefaults(defineProps<Props>(), {
  scales: () => []
})

const errorMessages = computed(() => {
  return props.scales.reduce((acc, scale) => {
    const scaleErrors = Object.entries(scale.errors).map(([key, value]) => {
      return value.map((error: string) => `${scale.commonInfo.title} - ${error}`)
    })
    return acc.concat(scaleErrors.flat())
  }, [])
})
</script>

<template>
  <app-tooltip
      v-if="props.scales.length > 0"
      bottom
      class="task-error"
  >
    <template #activator>
      <alert-circle-icon class="task-error__icon"/>
    </template>

    <ul>
      <li
          v-for="error in errorMessages"
      >
        {{ error }}
      </li>
    </ul>
  </app-tooltip>

</template>

<style lang="scss">
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }

}
.task-error {
  position: absolute;
  right: 56px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 40;
  &__icon {
    color: $red;
    width: 24px;
    height: 24px;
    cursor: help;
    animation: pulse 1s infinite;

  }
  .tooltip__body {
    width: 250px;
    ul {
      padding-left: 10px;
      color: #fff;
      li {
        font-size: 12px;
        text-align: left;
        text-transform: none!important;
        font-weight: 400!important;
      }
    }
  }
}
</style>