<template>
  <div
      class="file-download-card"
      :style="{'background-color': color}"
      :class="[{'file-download-card_disabled': disabled}]"
      v-show="links.length"
  >
    <slot/>
    <div
        class="file-download-card__item"
        v-for="(link, index) in links"
        :key="index"
    >
      <a
          :href="link[linkValue]"
          :download="link[linkLabel]"
      >
        {{ link[linkLabel] }}
      </a>
      <slot
          name="item-append"
          v-bind:item="link"
          v-bind:index="index"
      />
    </div>
    <slot name="append"/>
  </div>
</template>

<script>
export default {
  name: "fileDownloadCard",
  props: {
    color: {
      type: String,
      default: '#F0F1F7'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    links: {
      type: Array,
      default: () => []
    },
    linkValue: {
      type: String,
      default: 'original_url'
    },
    linkLabel: {
      type: String,
      default: 'file_name'
    },
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>