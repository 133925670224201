<script
    setup
    lang="ts"
>
import AppDataTable from "@/components/UI/AppDataTable/AppDataTable.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import ScaleMarkAutocomplete from "@/components/workspace/ScaleMarkAutocomplete.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import useRespondentsStore from "@/store/respondents.store.ts";

const props = defineProps<{
  scales: any
  results: {
    current: number | null
    total: number | null
    current_with_weight: number | null
    total_with_weight: number | null
  }
  readonly?: boolean
}>();
const emit = defineEmits(['setMark']);

const respondentsStore = useRespondentsStore();
const customScales = ref(props.scales);

const headers = computed(() => {
  {
    const items = [
      {
        title: 'Название',
        key: 'name'
      },
      {
        title: 'Шаг',
        key: 'step'
      },
      {
        title: 'Оценка',
        key: 'value'
      }
    ]

    if(!isEveryScaleWeightOne.value) {
      items.push({
        title: 'Оценка (со множителем)',
        key: 'weightedValue'
      })
    }

    return items;
  }
})
const isEveryScaleWeightOne = computed(() => {
  return props.scales.every(i => i.weight === 1);
})

watch(() => props.scales, () => {
  customScales.value = props.scales;
})
</script>

<template>
  <app-data-table
      :headers="headers"
      header-title-key="title"
      header-value-key="key"
      :items="customScales"
      :is-loading="respondentsStore.isRespondentsLoading"
  >
    <template #thead.weightedValue="{item}">
      Со множителем
    </template>
    <template #name="{item}">
      {{ item.name }}
    </template>
    <template #value="{item}">
      <scale-mark-autocomplete
          v-if="!props.readonly"
          v-model="item.current"
          :items="item.grades"
          :min="item.min"
          :max="item.max"
          @success="() => emit('setMark', item)"
      />

      <template v-else>
        {{ item.current !== null ? item.current : '-' }}
        /
        {{ item.max }}
      </template>
    </template>
    <template #weightedValue="{item}">
      <div class="custom-assessment__form__weighted-value">
        {{ item.current === null ? '-' : item.current_with_weight }} / {{ item.max_with_weight }}

        <app-tooltip class="custom-assessment__form__weighted-value__tooltip" left>
          <template #activator>
            <info-icon/>
          </template>
          <div class="custom-assessment__form__weighted-value__tooltip__content">
            Множитель: <b>{{item.weight}}</b> <br/>
            Набранный балл: <b>{{item.current ?? '-'}}</b>

            <template v-if="item.current !== null">
              <br/>
              <br/>
              {{`${item.weight} * ${item.current} = ${item.current_with_weight}`}}
            </template>
          </div>
        </app-tooltip>
      </div>
    </template>

    <template #tbody-append>
      <tr
          v-if="scales.length"
          class="custom-assessment__form__total-tr"
      >
        <td>
          <strong>Итого</strong>
        </td>
        <td></td>
        <td>
          <strong>
            {{ props.results.current !== null ? props.results.current : '-' }} / {{ props.results.total }}
          </strong>
        </td>
        <td v-if="!isEveryScaleWeightOne">
          <strong>
            {{ props.results.current === null ? '-' : props.results.current_with_weight }} / {{ props.results.total_with_weight }}
          </strong>
        </td>
      </tr>
    </template>
  </app-data-table>
</template>