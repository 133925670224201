<template>
  <mt-loading-animation-linear v-if="!isLoaded || !assessmentPlan" />
  <div
      v-else
      :class="['respondents-table',
          {'respondents-table_loading': isRespondentsLoading}
      ]"
      style="width: 100%"
  >
    <div class="respondents-table__header">
      <app-tooltip top>
        <template #activator>
          <mt-button
              icon
              color="light-gray"
              :loading="exportRequestPending"
              @click="exportUsers"
          >
            <export-icon />
          </mt-button>
        </template>
        <template #default>Экспорт</template>
      </app-tooltip>

      <mt-filters
          style="margin-left: auto;"
          v-model:cols="cols"
          v-model:filters="filters"
          v-model:sort="sort"
          v-model:search="search"
          :filtersSettings="filtersSettings"
          :filter-visibility="false"
          @change="loadRespondents({page: 1})"
      />
    </div>
    <div class="respondents-table__body">
      <div
          ref="table"
          class="respondents-table__wrapper"
          v-dragscroll
      >
        <table>
          <thead>
          <tr>
            <th v-if="cols[0].visible">
              <div class="respondents-table__cell">Респондент</div>
            </th>
            <th v-if="cols[1].visible">Возраст</th>
            <template
                v-for="(test, index) in testsCount"
                :key="index"
            >
              <th class="respondents-table__border-left">
                <div class="respondents-table__cell">
                  <task-icon
                      :type="test.type"
                      size="small"
                      class="respondents-table__header__icon mr-1"
                  />

                  <span>{{ test.name }}</span>
                  <mt-button
                      v-if="test.scales?.length"
                      icon
                      :class="[
                          'respondents-table__header__toggler',
                          {
                            'respondents-table__header__toggler_active': test.uuid === activeTestScaleColumn
                          }
                        ]"
                      @click="toggleTestScaleColumnVisibility(test)"
                  >
                    <span class="respondents-table__header__toggler__icon" />
                  </mt-button>
                </div>
              </th>
              <th
                  v-show="test.uuid === activeTestScaleColumn"
                  v-for="(scale, index) in test.scales"
                  :key="index"
              >
                {{ scale.name }}
              </th>
            </template>
            <th class="respondents-table__border-left">Балл</th>
            <th class="respondents-table__border-left">Оценка</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(respondent, index) in respondents"
              :key="respondent.uuid"
          >
            <td v-if="cols[0].visible">
              <span class="respondents-table__cell_fullname">
                {{ respondent.full_name }}
              </span>
            </td>
            <td v-if="cols[1].visible">{{ respondent.year }}</td>
            <template v-for="(task, index) in respondent.tests">
              <td
                  v-if="task.type === 'confirmation'"
                  :key="index"
                  class="respondents-table__border-left"
              >
                <mt-checkbox
                    v-model="task.isChecked"
                    disabled="disabled"
                    class="ml-auto mr-auto"
                />
              </td>
              <template v-else>
                <td
                    :key="index"
                    :class="[
                        'respondents-table__border-left',
                        'respondents-table__td',
                        {
                          'respondents-table__td_expert': task.type === 'expert'
                        }
                    ]"
                >
                  <div class="respondents-table__cell">
                    <template v-if="task.is_calculating">
                      Cчитывается...
                    </template>
                    <template v-else>
                      <component
                          :is="canEvaluateTask(task) ? 'button' : 'div'
                          "
                          :class="[
                            'respondents-table__cell__more',
                            {
                              'respondents-table__cell__more_failed': task.failed,
                              'respondents-table__cell__more_expert': canEvaluateTask(task)
                            }
                          ]"
                          @click="isTaskTypeExpertAndPlanNotCompleted(task) ? openTaskModal(task, respondent) : null"
                      >
                        <template v-if="!task.current && !task.total_with_weight">
                          -
                        </template>
                        <template v-else>
                          {{ !task.completed ? '-' : task.current_with_weight }} из {{ task.total_with_weight }}
                        </template>

                        <button
                            v-if="task.reports?.length"
                            class="ml-2"
                            @click.stop="openDownloadReportModal(respondent.uuid, task)"
                        >
                          <mtSvg
                              name="download"
                              color="#00788C"
                          />
                        </button>
                        <img
                            v-if="task.notifications"
                            src="/img/icons/notification.svg"
                            alt=""
                            class="respondents-table__cell__notification"
                        />
                      </component>
                    </template>
                  </div>
                </td>
                <td
                    v-show="task.uuid === activeTestScaleColumn"
                    v-for="(scale, scaleIndex) in task.scales"
                    :key="scaleIndex"
                    class="respondents-table__cell_scale"
                >
                  <template v-if="scale.current === 0 && scale.max === 0">
                    -
                  </template>
                  <template v-else>
                    <Popper
                        v-if="canEvaluateTask(task)"
                        :show="openedPopper === `${respondent.uuid} ${scale.uuid}`"
                        arrow
                        class="respondents-table__evaluation-trigger"
                        @open:popper="onOpenScaleMarkRangePopper(respondent.uuid, scale.uuid)"
                    >
                      <button
                          class="respondents-table__evaluation-trigger__content"
                          @click="openRangePopover(scale, task, respondent)"
                      >
                        <span>
                          {{ (!task.completed || scale.current === null) ? '-' : scale.current_with_weight }} из {{ scale.max_with_weight }}
                        </span>

                        <span class="respondents-table__evaluation-trigger__status">
                          <app-circle-loader
                              size="small"
                              :class="['respondents-table__evaluation-trigger__loader', {
                                  'respondents-table__evaluation-trigger__loader_active': false
                                }]"
                          />

                          <tick-icon
                              :class="['respondents-table__evaluation-trigger__loader', {
                                  'respondents-table__evaluation-trigger__loader_active': false
                                }]"
                          />
                        </span>

                        <span
                            class="respondents-table__evaluation-trigger__triangle"
                            @click.stop="isTaskTypeExpertAndPlanNotCompleted(task) ? openTaskModal(task, respondent) : null"
                        >
                          <arrow-diagonal-icon class="respondents-table__evaluation-trigger__triangle__icon" />
                        </span>
                      </button>

                      <template #content>
                        <div>
                          <div class="d-flex">
                            <div class="scale-mark-range__desc">
                              <p>
                                <strong>Шаг:</strong>
                                {{scale.step}}
                              </p>
                              <p>
                                <strong>Множитель: </strong>
                                {{ scale.weight }}
                              </p>
                            </div>

                            <scale-mark-autocomplete
                                v-model="scale.current"
                                id="scale_mark_range"
                                :key="scale.uuid"
                                :items="scale.grades"
                                :min="scale.min"
                                :max="scale.max"
                                :data-respondent="respondent.uuid"
                                :data-criteria="scale.uuid"
                                @success="setScaleMark(respondent.uuid, task.uuid, scale)"
                            />
                          </div>
                        </div>
                      </template>
                    </Popper>
                    <template v-else>
                      {{ !task.completed ? '-' : scale.current }} из {{ scale.max_with_weight }}
                    </template>
                  </template>
                </td>
              </template>
            </template>
            <td class="respondents-table__border-left">
              {{ respondent.balls }}
              <template v-if="respondent.is_calculating">
                (Cчитывается...)
              </template>
            </td>
            <td class="respondents-table__border-left">
              <template v-if="!respondent.is_calculating">
                {{ respondent.grade }}
              </template>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <mt-pagination
          v-if="pagination?.total"
          :total="pagination?.total"
          :page="pagination.page"
          :perPage="pagination.perPage"
          class="respondents-table__pagination"
          @change="loadRespondents"
      />
    </div>
  </div>
  <teleport to="body">
    <download-report-popover
        :visibility="isDownloadReportVisible"
        :reports="clipboardTask?.reports"
        @close="closeDownloadReportModal"
        @download="downloadReport"
        @downloadHTML="downloadReportHTML"
    />
  </teleport>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MtCheckbox from "@/components/UI/mtCheckbox/index.vue";
import MtPagination from "@/components/UI/mtPagination/mtPagination.vue";
import MtFilters from "@/components/UI/mtFilters/mtFilters.vue";
import MtSvg from "@/components/UI/mtSvg/index.vue";
import api from "@/network";
import {dragscroll} from "vue-dragscroll";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import FileDownloadCard from "@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import DownloadReportPopover from "@/components/workspace/testLibrary/results/DownloadReportPopover/index.vue";
import externalApi from "@/network/external";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import ExportIcon from "@/components/icons/ExportIcon.vue";
import MtPopover from "@/components/UI/mtPopover/mtPopover.vue";
import MtLinearProgress from "@/components/UI/mtLinearProgress/mtLinearProgress.vue";
import ScaleMarkRange from "@/components/workspace/ScaleMarkRange.vue";
import AppCircleLoader from "@/components/UI/AppCircleLoader.vue";
import Popper from "vue3-popper"
import TaskIcon from "@/components/workspace/assessmentPlans/plans/TaskIcon.vue";
import TickIcon from "@/components/icons/TickIcon.vue";
import PlayIcon from "@/components/icons/PlayIcon.vue";
import ScaleMarkAutocomplete from "@/components/workspace/ScaleMarkAutocomplete.vue";
import ArrowDiagonalIcon from "@/components/icons/ArrowDiagonalIcon.vue";
import usePlansStore from "@/store/plans.store";
import {storeToRefs} from "pinia";
import useRespondentsStore from "@/store/respondents.store";

export default {
  name: "ExpertReviewTable",
  components: {
    ArrowDiagonalIcon,
    ScaleMarkAutocomplete,
    PlayIcon,
    TickIcon,
    TaskIcon,
    AppCircleLoader,
    ScaleMarkRange,
    MtLinearProgress,
    MtPopover,
    ExportIcon,
    MtButton,
    AppTooltip,
    DownloadReportPopover,
    MtLoadingAnimationLinear,
    FileDownloadCard,
    MtDialog,
    MtSvg,
    MtFilters,
    MtPagination,
    MtCheckbox,
    Popper
  },
  directives: {dragscroll},
  data: () => ({
    cols: [
      {
        "title": "ФИО",
        "stub": "full_name",
        "visible": true,
        "filter": "string",
        "filterable": false,
        "sortable": true,
        "showable": false
      },
      {
        "title": "Возраст",
        "stub": "birthday",
        "visible": true,
        "filter": "date",
        "filterable": false,
        "sortable": true,
        "showable": true
      }
    ],
    pagination: {
      page: 1,
      perPage: 15,
      total: 0,
    },
    filtersSettings: {},
    sort: null,
    search: {},
    filters: [],

    clipboardTask: null,
    selectedResultsReport: [],

    isDownloadReportVisible: false,
    isLoaded: false,

    activeTestScaleColumn: null,
    exportRequestPending: false,
    openedPopper: ''
  }),
  setup() {
    const plansStore = usePlansStore();
    const {plan} = storeToRefs(plansStore);

    const respondentsStore = useRespondentsStore();
    const {respondents, isRespondentsLoading} = storeToRefs(respondentsStore);

    return {
      assessmentPlan: plan,
      respondentsStore,
      respondents,
      isRespondentsLoading
    }
  },
  computed: {
    ...mapGetters(['authUser', 'authUserRoleSlug']),

    isUserAdmin() {
      return this.authUserRoleSlug === 'admin';
    },

    testsCount() {
      if (this.respondents.length === 0) return [];

      return this.respondents[0].tests;
    }
  },
  methods: {
    ...mapActions('results', ['exportResultsReportHTML']),

    isTaskTypeExpertAndPlanNotCompleted(task) {
      return task.type === 'expert' && !this.assessmentPlan.isCompleted;
    },

    canEvaluateTask(task) {
      const accessAbleRoles = ['expert', 'inspector', 'admin'];
      const userHasAccess = accessAbleRoles.includes(this.authUserRoleSlug);

      return this.isTaskTypeExpertAndPlanNotCompleted(task) && userHasAccess;
    },

    async downloadReport({format, variants, titles}) {
      const payload = [
        {
          format,
          variants,
          selected_results_report: this.selectedResultsReport
        }
      ];
      const response = await api.post('export/results', payload);
      if (response.data?.url) {

        const reportLink = response.data.url;
        const fileNameInfo = response.data.FileNameInfo;
        const isGroup = variants.length > 1;

        if (isGroup) {
          window.open(reportLink, "_blank");
        } else {
          const response = await externalApi.get(`${reportLink}`, {
            responseType: 'blob',
          });

          if (response.data.size) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;

            //let fileName = `results__${ new Date().toLocaleDateString() }-${ new Date().toLocaleTimeString().replace(/:/g, '_') }.${ type }`;
            let fileName = fileNameInfo['FullName'] + '_' + fileNameInfo['TestName'] + '_' + titles[0] + '_' + fileNameInfo['TestNumber'] + '.' + format;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            this.$toast.error('Отчёт не найден');
          }
        }
      }
    },

    async downloadReportHTML({variant}) {
      const payload =
          {
            uuid_session: this.selectedResultsReport[0].uuid_session,
            variant: variant
          };

      await this.exportResultsReportHTML(payload).then((response) => {
        if (response.data?.url) {
          window.open(response.data.url, "_blank");
        }
      })
    },

    async loadRespondents(newPagination) {
      let params = {
        page: newPagination?.page ?? this.pagination.page,
        per_page: newPagination?.perPage ?? this.pagination.perPage,
        settings: {}
      };

      if(this.sort) {
        params.settings.field = this.sort.field;
        params.settings.order = this.sort.order;
      }

      if (this.filters?.length) {
        params = {
          ...params,
          settings: {
            ...params.settings,
            filters: this.filters,
          },
        };
      }

      if (this.filterByResult) {
        params = {
          ...params,
          result: this.filterByResult,
        };
      }

      if (!!this.search.value) {
        params.settings["value"] = this.search.value;

        if (!!this.search.search) {
          params.settings["search"] = this.search.search;
        }
      }

      const {meta} = await this.respondentsStore.getRespondents(this.$route.params.planUuid, params);
      this.pagination = {
        ...this.pagination,
        page: meta.current_page,
        perPage: meta.per_page,
        total: meta.last_page
      }
    },

    exportUsers() {
      this.exportRequestPending = true;
      api.get(`export/plans/${this.$route.params.planUuid}`, {
        responseType: 'blob'
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;

            let fileName = `Таблица_ведомости__${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString().replace(/:/g, '_')}.xlsx`;

            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .finally(() => {
            this.exportRequestPending = false;
          })
    },

    toggleTestScaleColumnVisibility(test) {
      this.activeTestScaleColumn = this.activeTestScaleColumn === test.uuid ? null : test.uuid
    },

    openDownloadReportModal(userUuid, task) {
      this.isDownloadReportVisible = true;

      this.selectedResultsReport = [{'uuid_session': task.session_uuid, 'uuid_user': userUuid}];
      this.clipboardTask = task;
    },

    closeDownloadReportModal() {
      this.isDownloadReportVisible = false

      this.selectedResultsReport = [];
      this.clipboardTask = null;
    },

    openTaskModal(test, respondent) {
      const isAdminResultsPage = this.$route.name === 'AssessmentPlanSingleResults';
      const adminResultsPageRouteName = 'PlanSingleResultsExpertTaskSingle';
      const isExpRespPage = this.$route.name === 'ExpRespAssessmentPlanSingle';
      const expRespPageRouteName = 'ExpertTaskSingle';

      const taskRoute = isExpRespPage ? expRespPageRouteName : isAdminResultsPage ? adminResultsPageRouteName : '';

      this.$router.replace({
        name: taskRoute,
        params: {
          planUuid: this.$route.params.planUuid,
          taskUuid: test.uuid,
          respondentUuid: respondent.uuid
        }
      })
    },

    async openRangePopover(criteria, test, respondent) {
      this.openedPopper = `${respondent.uuid} ${criteria.uuid}`;
    },

    async setScaleMark(respondentUuid, taskUuid, scale) {
      const params = {
        respondentUuid,
        planUuid: this.$route.params.planUuid,
        taskUuid,
        scale
      }
      if (!this.isUserAdmin) {
        console.log('getprogress')
        // this.getPlanProgress({
        //   uuid: this.$route.params.planUuid
        // });
      }

      this.respondentsStore.setMarkToRespondentTaskScale(params);
    },

    onOpenScaleMarkRangePopper(respondent_uuid, criteria_uuid) {
      const target = document.querySelector(`#scale_mark_range[data-respondent="${respondent_uuid}"][data-criteria="${criteria_uuid}"]`);
      const input = target.querySelector('.app-autocomplete__input');
      nextTick(() => {
        input?.focus();
      })
    },

    onClickOutsideOfPopper(e) {
      if(e.target.closest('.popper')) return;

      const trigger = e.target.closest('.respondents-table__evaluation-trigger__content');
      if(trigger) {
        const popper = trigger.parentNode.nextSibling;
        const isActive = window.getComputedStyle(popper)['display'] === 'block';
        if(!isActive) return
      }

      this.openedPopper = '';
    },
  },
  mounted() {
    this.loadRespondents().finally(() => {
          this.isLoaded = true
        })

    document.addEventListener('click', this.onClickOutsideOfPopper)
  },
  unmounted() {
    document.removeEventListener('click', this.onClickOutsideOfPopper)
  }
}
</script>

<style lang="scss">
.respondents-table {
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  position: relative;
  width: fit-content;
  max-width: 100%;
  transition: .3s opacity;

  &__body {
    position: relative;
  }

  &_loading {
    opacity: 0.7;
    pointer-events: none;
  }

  &__sort-icon {
    cursor: pointer;
    transition: transform .3s;

    &--active {
      transform: rotate(180deg);
    }
  }

  td.respondents-table__cell {
    white-space: pre-wrap;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  &__cell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &_fullname {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      white-space: nowrap;

      &:focus {
        color: $green!important;
      }
    }

    &__notification {
      width: 16px;
      margin-left: 8px;
    }

    &__more {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      height: 100%;
      width: 100%;

      &_failed {
        color: #C24020;
      }

      &_expert {
        color: $black;
        text-decoration: underline;

        &:focus {
          color: $green;
        }
      }
    }
  }

  a.respondents-table__cell_fullname {
    color: #000;
    text-decoration: underline;
    transition: color .3s;

    &:hover {
      color: #00788C;
    }
  }

  &__edit {
    width: 18px;
    height: 18px;
    margin-left: 8px;

    svg {
      fill: $green;
    }
  }

  &__header {
    height: 57px;
    display: flex;
    align-items: center;
    padding: 0 24px;

    &__toggler {
      width: 32px;
      height: 32px;
      margin-left: 5px;

      &__icon {
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 8px solid $green;
      }

      &_active {
        transform: scale(-1);
      }
    }
  }

  &__wrapper {
    overflow-x: auto;
  }

  th, td {
    padding: 12px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #252733;
    vertical-align: middle;

    &:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }

  th {
    white-space: nowrap;
    background: #F0F1F7;
    box-shadow: inset 0px -1px 0px #E6E7E9;
  }

  td {
    height: 41px;
    padding: 0;

    &:first-child {
      background: #fff;
    }
  }

  td.respondents-table__cell_scale {
    padding: 0;
  }

  tbody tr > * {
    border-bottom: 1px solid #DFE0EB;
    min-width: 130px;
  }

  table {
    width: 100%;
  }

  &__header {
    &__icon {
      &_pre {
        margin-right: 16px;
      }
    }
  }

  &__border-right {
    border-right: 2px solid #E6E7E9;
  }

  &__border-left {
    border-left: 2px solid #E6E7E9;
  }

  &__td {
    &_expert {
      /*padding: 0!important;
      .respondents-table__cell {
        padding: 12px 10px;
        background-color: $light-green;
        color: $green;
        cursor: pointer;
        &:hover {
          background-color: darken($light-green, 5%);
        }
        &:active {
          background-color: darken($light-green, 15%);
        }
      }*/
    }
  }

  &__pagination {
    padding: 0 20px;
    justify-content: flex-end;
    min-height: 60px;
  }

  .respondents-table__evaluation-trigger {
    display: flex !important;
    justify-content: center;
    margin: 0!important;
    border: none!important;
    height: 100%;

    > div:first-child {
      width: 100%;
      height: 100%;
    }

    > div:last-child {
      width: 300px;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      white-space: nowrap;
      height: 100%;
      gap: 5px;
      position: relative;

      span {
        &:first-child {
          margin-left: 20px;
        }
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0 7px 12px rgba(0, 0, 0, 0.1);

        .respondents-table__evaluation-trigger__triangle {
          opacity: 1;
        }
      }

      &:focus, &:active {
        background-color: #eee;
      }
    }

    &__status {
      width: 15px;
      position: relative;

      > svg {
        width: 100%;
      }
    }

    &__loader {
      opacity: 0;

      &_active {
        opacity: 1;
      }

      &:last-child {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $green;
      }
    }

    &__triangle {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid $green;
      border-bottom: 15px solid transparent;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      transition: opacity .3s;

      &__icon {
        width: 10px;
        height: 10px;
        transform: rotate(-90deg);
        margin-left: 5px;
        margin-bottom: 3px;
        color: #fff;
      }
    }
  }
}

.expert-plan {
  &__export-btn {
    background: #F0F1F7;
    border-radius: 8px;
    height: 33px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.custom-assessment {

  &__expander {
    margin-bottom: 20px;

    &__title {
      font-weight: 700;
      font-size: 19px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #252733;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
      white-space: pre-wrap;
    }

    &__file-title {
      font-weight: 600;
      margin-top: 30px;
    }

    .expander {
      &__item {
        &__header {
          padding-top: 0;
          @include max-xs {
            flex-wrap: nowrap;
            max-width: 100%;
          }
        }
      }
    }
  }
}

:root {
  --popper-theme-background-color: #fff;
  --popper-theme-background-color-hover: #fff;
  --popper-theme-text-color: #000;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 10px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>