<template>
  <div class="mt-page-nav">
    <mt-button
        class="mt-page-nav__button mt-page-nav__button_back"
        @click="onBackClicked"
        :loading="backBtnLoading"
        color="light-green"
    >
      <slot name="back">Назад</slot>
    </mt-button>

    <app-tooltip
        :disabled="!hasUploadingFiles"
        class="tasks-form__button"
    >
      <template #activator>
        <mt-button
            class="mt-page-nav__button mt-page-nav__button_next"
            :loading="nextBtnLoading"
            :disabled="hasUploadingFiles"
            @click="onNextClicked"
        >
          <slot name="next">Далее</slot>
        </mt-button>
      </template>

      Пожалуйста, дождитесь окончания загрузки файлов
    </app-tooltip>
  </div>
</template>

<script>
import mtButton from "@/components/UI/mtButton/index.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";

export default {
  components: {
    AppTooltip,
    mtButton,
  },
  props: {
    nextBtnLoading: {
      type: Boolean,
      default: false
    },

    hasUploadingFiles: {
      type: Boolean,
      default: false
    },

    backBtnLoading: {
      type: Boolean,
      default: false
    },
  },
  emits: ['back', 'next'],
  data() {
    return {};
  },

  methods: {
    onBackClicked() {
      this.$emit('back');
    },
    onNextClicked() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-page-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    user-select: none;
    color: #ffffff;

    &-back {
      color: #00788c;
    }
  }

  &__button {
    min-width: 130px;
    text-align: center;
    cursor: pointer;
    margin-top: 0 !important;

  }
}
</style>