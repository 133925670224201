<script
    setup
    lang="ts"
>
export type TabsNavItemType = {
  title: string;
  value: string;
  text?: string;
  icon?: Object | string | Component;
  [key: string]: any;
};

type PassThroughProps = {
  item?: {
    class?: string;
    activeClass?: string;
  }
}

type PropsType = {
  modelValue: string;
  items: Array<TabsNavItemType>;
  column?: boolean;
  hasIndicator?: boolean
  pt?: PassThroughProps;
};

const props = withDefaults(defineProps<PropsType>(), {
  column: false,
  hasIndicator: false
});

const emit = defineEmits(['update:modelValue']);

const slots = useSlots();
const indicatorRef = ref(null);

function indicatorMove() {
  const activeItem = document.querySelector('.tabs-nav__item--active');

  if (activeItem && indicatorRef.value) {
    if(props.column) {
      indicatorRef.value.style.height = `${activeItem.clientHeight}px`;
      indicatorRef.value.style.transform = `translateY(${activeItem.offsetTop}px)`;
      return;
    }

    indicatorRef.value.style.width = `${activeItem.clientWidth}px`;
    indicatorRef.value.style.transform = `translateX(${activeItem.offsetLeft}px)`;
  }
}

function change(value: string) {
  emit('update:modelValue', value);
  nextTick(() => {
    indicatorMove()
  })
}

onMounted(() => {
  indicatorMove()
});

watch(() => props.modelValue, () => {
  nextTick(() => {
    indicatorMove()
  })
});
</script>

<template>
  <div
      :class="['tabs-nav', {
        'tabs-nav--column': column,
      }]"
  >
    <div
        v-if="props.hasIndicator"
        ref="indicatorRef"
        class="tabs-nav__indicator"
    />
    <button
        v-for="(item, index) in items"
        :key="index"
        :class="['tabs-nav__item', pt?.item?.class, {
          [`tabs-nav__item--active ${pt?.item?.activeClass}`]: item.value === modelValue
        }]"
        @click="change(item.value)"
    >
      <template v-if="!slots[`item.${item.value}`]">
        <slot
            name="item"
            v-bind="{item, order: index + 1}"
        />
        <template v-if="!slots.item">
          {{ item.title }}
        </template>
      </template>
      <template v-else>
        <slot
            :name="`item.${item.value}`"
            v-bind="{item, order: index + 1}"
        />
      </template>
    </button>
  </div>
</template>

<style lang="scss">
.tabs-nav {
  display: flex;
  position: relative;
  margin-bottom: 20px;

  &__indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: $green; /* Replace with your primary color variable */
    transition: all 0.3s ease;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    transition: color 0.3s ease;
    border-radius: 10px 10px 0 0;

    &:hover {
      color: $dark-green; /* Replace with your hover primary color variable */
    }

    &:focus {
      color: $green;
      background: #e9f0f1;
    }

    &--active {
      color: $green; /* Replace with your primary color variable */
      opacity: 1;
    }
  }

  &--column {
    flex-direction: column;

    .tabs-nav__indicator {
      width: 2px;
      left: -1px;
      top: 0;
    }
  }
}
</style>