<script setup>
import { ref } from 'vue'
import mtButton from '@/components/UI/mtButton/index.vue'
import validation from '@/plugins/validation.js'
import AuthWrapper from '@/components/workspace/auth/AuthWrapper/index.vue'
import AuthApi from '@/api/auth.api.ts'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import useVuelidate from '@vuelidate/core'
import useToast from '@/components/UI/AppToast/useToast'
import { useValidations } from '@/plugins/vuelidate-wrapper'

const { required, email: isEmail } = useValidations()

const rules = {
  email: {
    required,
    isEmail,
  },
}

const payload = ref({
  email: null,
})
const isRequestPending = ref(false)
const isSuccessfullySent = ref(false)
const { toast } = useToast()
const v$ = useVuelidate(rules, payload)

async function send() {
  const isValid = await v$.value.$validate()

  if (!isValid) {
    return
  }

  isRequestPending.value = true
  AuthApi.forgotPassword(payload.value.email)
    .then((resource) => {
      isSuccessfullySent.value = true
    })
    .catch((error) => {
      toast.error('E-mail не найден')
    })
    .finally(() => {
      isRequestPending.value = false
    })
}
</script>

<template>
  <auth-wrapper>
    <template #title>Забыли пароль?</template>
    <template #body>
      <div class="auth__fields" v-if="!isSuccessfullySent">
        <div class="auth__field">
          <app-input
            class="auth__field__item"
            v-model="payload.email"
            :errors="v$.email.$errors"
            @keyup.enter="send()"
          >
            <template #label> E-mail </template>
          </app-input>
        </div>
        <mt-button
          class="auth__actions__btn"
          :loading="isRequestPending"
          @click="send()"
        >
          Продолжить
        </mt-button>
      </div>
      <div class="forgot__second-step" v-else>
        <p class="forgot__second-step__description">
          Письмо с инструкцией отправлено на E-mail.
        </p>
        <mt-button
          class="auth__actions__btn"
          :loading="isRequestPending"
          :to="{ name: 'Signin' }"
        >
          Войти
        </mt-button>
      </div>

      <div class="auth__actions__link" v-if="!isSuccessfullySent">
        <router-link class="auth__actions__link__value" to="/auth/signin">
          Войти
        </router-link>
      </div>
    </template>
  </auth-wrapper>
</template>

<style lang="scss">
.forgot__second-step {
  &__description {
    text-align: center;
    margin-bottom: 15px;
  }
}
</style>
