<script
  setup
  lang="ts"
>
type PassThroughProps = {
  item: any
}

type PropsType = {
  modelValue: string;
  items: Array<{
    title: string;
    value: string;
  }>;
  column?: boolean;
  pt?: PassThroughProps;
}

const props = withDefaults(defineProps<PropsType>(), {
  column: false
});
const slots = useSlots();

const tabsRef = ref(null);

function setTabHeight() {
  if (!tabsRef.value) return;

  const tabs = tabsRef.value as HTMLElement;
  const items = tabs.querySelectorAll(".tabs__item") as NodeListOf<HTMLElement>;
  const activeItem = tabs.querySelector(".tabs__item--active");

  items.forEach((item: HTMLElement) => {
    item.classList.add("tabs__item--absolute");
  });

  if (activeItem) {
    tabs.style.minHeight = `${activeItem.clientHeight}px`;
    activeItem.classList.remove("tabs__item--absolute");
  }
}

watch(() => props.modelValue, () => {
  nextTick(() => {
    setTabHeight()
  })
});
</script>

<template>
  <div
    ref="tabsRef"
    :class="['tabs', {
      'tabs--column': props.column
    }]"
  >
    <template
      v-for="(item, index) in items"
      :key="index"
    >
      <transition name="tabs">
        <div
          v-show="item.value === modelValue"
          :class="['tabs__item', {
            'tabs__item--active': item.value === modelValue
          }]"
          v-bind="pt && pt.item"
        >
          <template v-if="slots.item">
            <slot
              name="item"
              v-bind="{item, order: index + 1}"
            />
          </template>
          <template v-else>
            <slot
              :name="`item.${item.value}`"
              v-bind="{item, order: index + 1}"
            />
          </template>
        </div>
      </transition>
    </template>
  </div>
</template>

<style lang="scss">
.tabs {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: height 0.5s;
  overflow-x: hidden;

  &__item {
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    background-color: white;

    &--absolute {
      position: absolute;
    }
  }

  &--column {
    min-height: 100%;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.5s;
  }

  &-enter-from,
  &-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
}
</style>