<script
    setup
    lang="ts"
>
import { computed, ref } from 'vue'
import mtTimeIntervalField from '@/components/composite/mtTimeIntervalField.vue'
import mtButton from '@/components/UI/mtButton/index.vue'
import Alert from '@/components/UI/AppAlert/AppAlert.vue'
import AppSelect from '@/components/UI/AppSelect/AppSelect.vue'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import AppCheckbox from '@/components/UI/AppCheckbox/AppCheckbox.vue'
import AppTextarea from '@/components/UI/AppTextarea/AppTextarea.vue'
import useVuelidate from '@vuelidate/core'
import useToast from '@/components/UI/AppToast/useToast.js'
import AppFilesUploader from '@/components/composite/AppFilesUploader.vue'
import { deepClone } from '@/services/utils.js'
import MtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue'
import TaskType from '@/enums/task-type.enum.ts'
import DoubleCheckIcon from "@/components/icons/DoubleCheckIcon.vue";
import TestsAdminApi from "@/api/tests-admin.api.ts";
import { useValidations } from '@/plugins/vuelidate-wrapper'
import AppAutocomplete from '@/components/UI/AppAutocomplete/AppAutocomplete.vue'
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";

interface Props {
  submit: (payload: any) => Promise<any>
  predefinedData?: any
  testsForExclude: any[]
  buttonText?: string
  resetAfterSubmit?: boolean
  type?: TaskType
  isPro?: boolean
}

const defaultValue = {
  mainTest: null,
  name: null,

  description: null,
  description_expert: null,
  group: null,
  date_from: null,
  date_to: null,
  number_attempt: 1,
  resultAttempt: '',
  input_text: false,
  upload_files: false,
  files: [],
  expert_files: [],
  audience: [],
  view_respondent_expert_task: true,
}

const {requiredIf, required} = useValidations()

const props = withDefaults(defineProps<Props>(), {
  buttonText: 'Применить',
  resetAfterSubmit: true,
  type: TaskType.TEST,
  isPro: false,
})

const computedParamType = computed(() => {
  if (props.type === TaskType.TEST || props.type === TaskType.TEST_PRO) return 'auto'

  return 'expert'
})

const initialValue = props.predefinedData || defaultValue
const payload = ref({
  ...initialValue,
  mainTest: props.predefinedData?.mainTest?.uuid || null,
})

const attempts = [
  {
    value: 1,
    name: '1',
  },
  {
    value: 2,
    name: '2',
  },
  {
    value: 3,
    name: '3',
  },
  {
    value: 4,
    name: '4',
  },
  {
    value: 5,
    name: '5',
  },
  {
    value: -1,
    name: '∞',
  },
]
const resultAttempts = [
  {
    slug: 'first',
    name: 'Первую',
  },
  {
    slug: 'last',
    name: 'Последнюю',
  },
  {
    slug: 'best',
    name: 'Лучшую',
  },
]
const mainTests = ref([])
const autocompleteRef = ref();
const computedMainTests = computed(() => {
  return mainTests.value.map((item: any) => {
    item.alreadyExists = props.testsForExclude.includes(item.uuid);
    item.readonly = item.alreadyExists;
    item.disabled = !item.status;

    return item;
  })
})

const testsAreLoaded = ref(false)

watch(() => props.isPro, fetchMainTests, {immediate: true})

async function fetchMainTests(isPro) {
  TestsAdminApi.getAll({
    page: 1,
    per_page: 10000,
    is_pro: isPro ? 1 : 0,
    settings: {}
  })
      .then((response) => {
        mainTests.value = response.data.data
      })
      .finally(() => {
        testsAreLoaded.value = true
      })
}

const noAvailableTests = computed(() => {
  return (props.type === TaskType.TEST || props.type === TaskType.TEST_PRO) &&
      computedMainTests.value.every(item => item.readonly) &&
      testsAreLoaded.value;
})

const isFileUploading = computed(() => {
  let result = false;

  if (payload.value.files.length) {
    payload.value.files.some((file) => {
      if (file.isLoading) {
        result = true;
        return true;
      }
    });
  }
  if (payload.value.expert_files.length) {
    payload.value.expert_files.some((file) => {
      if (file.isLoading) {
        result = true;
        return true;
      }
    });
  }

  return result;
})

function setDates(dates: any) {
  payload.value.date_from = dates.from
  payload.value.date_to = dates.to
}

function setTaskName() {
  if (payload.value.type === 'expert') {
    payload.value.name = ''
    return
  }

  const mainTest = mainTests.value.find(
      (item: any) => item.uuid === payload.value.mainTest,
  )
  if (mainTest) {
    payload.value.name = mainTest.name
  }
}

const requestPending = ref(false)

const v$ = useVuelidate(
    {
      mainTest: {
        required: requiredIf(() => {
          return payload.value.type === 'test' || payload.value.type === 'test_pro'
        }),
      },
      name: {
        required,
      },
      number_attempt: {
        required: requiredIf(() => payload.value.type === 'test' || payload.value.type === 'test_pro'),
      },
      registration_attempts: {
        required: requiredIf(() => payload.value.number_attempt > 1),
      },
    },
    payload,
)

const {toast} = useToast()

async function submit() {
  const isValid = await v$.value.$validate()

  if (!isValid) {
    toast.error('Пожалуйста, введите корректные данные')
    return
  }

  requestPending.value = true
  props.submit({
    ...payload.value,
    type: props.type,
    parameters: {
      param_type: computedParamType.value,
    },
  })
      .then(() => {
        if (props.resetAfterSubmit) {
          payload.value = deepClone(initialValue)
          payload.value.files = []
          payload.value.expert_files = []
          autocompleteRef.value?.clearSearch();
          v$.value.$reset()
        }
      })
      .finally(() => {
        requestPending.value = false
      })
}
</script>

<template>
  <div class="tasks-form">
    <div class="tasks-form__scrollable">
      <div class="tasks-form__header">
        <slot name="title" />
      </div>
      <div class="tasks-form__body">
        <div class="tasks-form__content">
          <div
              v-if="noAvailableTests"
              class="mb-2"
          >
            <alert type="error">
              Нет доступных тестов
            </alert>
          </div>

          <div v-if="props.type === TaskType.TEST || props.type === TaskType.TEST_PRO">
            <mt-loading-animation-linear v-if="!testsAreLoaded" />

            <app-autocomplete
                v-model="payload.mainTest"
                :items="computedMainTests"
                item-value="uuid"
                item-text="name"
                label="Выбор теста"
                ref="autocompleteRef"
                :disabled="!!predefinedData"
                @change="setTaskName"
                :errors="v$.mainTest.$errors"
                :item-readonly="(item) => item.readonly"
                :item-disabled="(item) => item.disabled"
                class="tasks-form__test-select"
            >
              <template #item="{item}">
                <double-check-icon
                    v-if="item.alreadyExists"
                    class="tasks-form__test-select__icon"
                    title="Тест уже добавлен"
                />
                {{ item.name }}
                <i v-if="!item.status"> - Отключен</i>
              </template>
            </app-autocomplete>
          </div>

          <app-input
              v-model="payload.name"
              label="Название"
              :errors="v$.name?.$errors"
          />

          <mt-time-interval-field
              :date-from="payload.date_from"
              :date-to="payload.date_to"
              clearable
              withTime
              @update-date="setDates"
          />

          <template v-if="props.type === TaskType.TEST || props.type === TaskType.TEST_PRO">
            <app-files-uploader
                label="Прикрепленные файлы"
                v-model="payload.files"
            />
            <div class="tasks-form__row">
              <div class="field field--half">
                <app-select
                    v-model="payload.number_attempt"
                    :items="attempts"
                    :errors="v$.number_attempt?.$errors"
                    label="Количество попыток"
                    from-top
                    item-text="name"
                    item-value="value"
                />
              </div>
              <div
                  :class="[
                  'field',
                  'field--half',
                  {
                    'field--disabled':
                      !payload.number_attempt || payload.number_attempt <= 1,
                  },
                ]"
              >
                <app-select
                    v-model="payload.registration_attempts"
                    :items="resultAttempts"
                    item-text="name"
                    item-value="slug"
                    label="Учитывать попытку"
                    from-top
                    :errors="v$.registration_attempts?.$errors"
                />
              </div>
            </div>
            <app-textarea v-model="payload.description">
              <template #label> Описание</template>
            </app-textarea>
          </template>
          <template v-if="props.type === TaskType.EXPERT">
            <div class="tasks-form__row">
              <div class="field field--half">
                <app-checkbox v-model="payload.input_text">
                  <template #label>Ввод текста</template>
                </app-checkbox>
              </div>
              <div class="field field--half">
                <app-checkbox v-model="payload.upload_files">
                  <template #label>Загрузка файлов</template>
                </app-checkbox>
              </div>
            </div>
            <app-checkbox v-model="payload.view_respondent_expert_task">
              <template #label>
                Показывать респонденту экспертное задание
              </template>
            </app-checkbox>
            <div class="tasks-form__row">
              <div class="field field--half">
                <app-files-uploader
                    label="Прикрепленные файлы для респондента"
                    v-model="payload.files"
                />
              </div>
              <div class="field field--half">
                <app-files-uploader
                    label="Прикрепленные файлы для эксперта"
                    v-model="payload.expert_files"
                />
              </div>
            </div>
            <div class="tasks-form__row">
              <div class="field field--half">
                  <app-textarea
                      v-model="payload.description"
                      hide-details
                  >
                    <template #label> Инструкция для респондента</template>
                  </app-textarea>
              </div>
              <div class="field field--half">
                <app-textarea
                    v-model="payload.description_expert"
                    hide-details
                >
                  <template #label>Инструкция для эксперта</template>
                </app-textarea>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="tasks-form__footer">
      <app-tooltip
          :disabled="!isFileUploading"
          class="tasks-form__button"
      >
        <template #activator>
          <mt-button
              class="tasks-form__button"
              :disabled="isFileUploading"
              :loading="requestPending"
              @click="submit()"
          >
            {{ props.buttonText }}
          </mt-button>
        </template>

        Пожалуйста, дождитесь окончания загрузки файлов
      </app-tooltip>
    </div>
  </div>
</template>

<style lang="scss">
.tasks-form {
  height: 100%;

  &__test-select {
    &__icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .field--half {
    .mt-file-view {
      width: calc(100% - 8px);
    }
  }

  &__button {
    margin: 0 auto;
    width: 100%;
  }

  &__header {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: $black;
  }

  &__row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .field--half:last-child {
      margin-right: 0;
    }
  }

  .field {
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &--full {
      width: 100%;
      margin-right: 0 !important;
    }

    &--half {
      width: calc(50% - 5px);
      margin-right: 0;

      &:last-child {
        margin-left: auto;
      }

      @include max-md {
        width: 100%;
      }
    }
  }

  &__header {
    margin-bottom: 30px;
  }

  &__scrollable {
    height: calc(100% - 50px);
    overflow: auto;
    padding: 0 10px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 40px;
    background-color: #fff;
  }

  .field {
    margin-right: 10px;

    @include max-xs {
      margin-right: 0;
    }
  }
}
</style>
