import { defineStore } from "pinia";
import plansAdminApi from "@/api/plans-admin.api.ts";

const plan = ref({});
const isPlanLoading = ref(false);

const usePlansStore = defineStore('plans', () => {
    async function getSingle(planUuid: string) {
        isPlanLoading.value = true;
        return await plansAdminApi.get(planUuid)
            .then(({data}) => {
                plan.value = {
                    ...data.data,
                    tests: data.data.structure
                };
            })
            .finally(() => {
                isPlanLoading.value = false;
            });
    }

    return {
        plan,
        getSingle,
        isPlanLoading
    }
})

export default usePlansStore;