<template>

  <auth-wrapper v-if="!successStep && !is_outdated_link">
    <template #title>
      Регистрация
    </template>

    <template #body>
      <h4 class="auth__subtitle">Вы собираетесь зарегистрироваться на план оценки “{{ planName }}”. Для продолжения регистрации введите e-mail, на который будет отправлен пароль для входа в систему.</h4>

      <div class="auth__fields">
        <div class="auth__field">
          <app-input
              class="auth__field__input signup__email-field"
              v-model="email"
              label="E-mail"
              @keyup.enter="send()"
              @input="clearErrors()"
              :errors="computedErrors"
          />
        </div>
      </div>
      <div class="auth__actions">
        <mt-button
            class="auth__actions__btn"
            :loading="requestPending"
            @click="send()"
        >
          Отправить
        </mt-button>
      </div>
    </template>
  </auth-wrapper>

  <auth-wrapper v-else-if="!successStep && is_outdated_link">
    <template #title>
      Регистрация
    </template>

    <template #body>
      <h4 class="auth__subtitle">Cсылка утратила свою актуальность. Вам необходимо получить новую ссылку.</h4>
    </template>
  </auth-wrapper>

  <auth-wrapper class="self-registration-success" v-else>
    <template #prepend>
      <img src="@/assets/img/success-mail.gif" alt="success" class="self-registration-success__image">
    </template>
    <template #title>
      {{ successMessageTitle }}
    </template>
    <template #body>
      <div class="auth__subtitle">{{ successMessageText }}</div>
      <div class="auth__subtitle">{{ successMessageText }}</div>
      <div class="auth__actions__link">
        <router-link
            class="auth__actions__link__value"
            :to="{ name: 'Signin' }"
            v-show="show_router_link"
        >
          Войти в кабинет
        </router-link>
      </div>
    </template>
  </auth-wrapper>
</template>

<script>
import AuthWrapper from "@/components/workspace/auth/AuthWrapper/index.vue";
import MtInput from "@/components/UI/mtInput/mtInput.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import validation from "@/plugins/validation";
import AuthApi from '@/api/auth.api.ts';
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import useVuelidate from "@vuelidate/core";

import { useValidations } from '@/plugins/vuelidate-wrapper'
import {helpers} from "@vuelidate/validators";

export default {
  name: "SelfRegistration",
  components: {AppInput, mtButton, MtInput, AuthWrapper },
  data() {
    return {
      email: null,
      requestPending: false,
      successStep: false,
      newUser: true,
      successMessageTitle: null,
      successMessageText: null,
      planName: '',
      is_outdated_link: false,
      show_router_link: false,

      apiErrorMessages: []
    }
  },
  computed: {
    computedErrors() {
      return [
        ...this.v$.email.$errors,
        ...this.apiErrorMessages.map(message => ({ $message: message }))
      ];
    }
  },
  setup() {
    return {
      v$: useVuelidate({
        $lazy: true,
      }),
    }
  },
  validations() {
    const { required, email } = useValidations()

    return {
      email: {
        required,
        email,
      }
    }
  },

  created () {
    AuthApi.getSelfRegistrationInfo({self_registration_code: this.$route.query.self_registration})
        .then((response) => {
          this.planName = response.data.plan_name;
          this.is_outdated_link = response.data.is_outdated_link;
        })
  },
  methods: {
    clearErrors() {
      this.apiErrorMessages = [];
      this.v$.$reset();
    },
    async send() {
      const valid = await this.v$.$validate();
      if (!valid) return;

      this.requestPending = true;

      const registerToken = this.$route.query.self_registration;

      AuthApi.selfRegister({
        email: this.email,
        self_registration: registerToken
      })
          .then((response) => {
            this.successStep = true;
            if (response.data.is_new_user) {
              this.successMessageTitle = 'Приглашение успешно отправлено!';
              this.successMessageTitle = 'На вашу почту отправлено письмо для продолжения регистрации в системе Maintest.';
              this.show_router_link = false;
            } else {
              this.successMessageTitle = 'Пользователь добавлен в план!';
              this.successMessageTitle = 'Пользователь с указанным e-mail найден в системе  и успешно добавлен в план оценки.';
              this.show_router_link = true;
            }
          })
          .catch(error => {
            if (error.data.code === 422) {
              this.apiErrorMessages.push(error.data.message.original[0])
            }
          })
          .finally(() => {
            this.requestPending = false
          });
    }
  }
}
</script>

<style lang="scss">

.signup__email-field {
  margin-bottom: 40px;
}

.self-registration-success {
  &__image {
    width: 100px;
    display: block;
    margin: 0 auto 20px;
  }
}
</style>