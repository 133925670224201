<script
    setup
    lang="ts"
>
import AppAutocomplete from "@/components/UI/AppAutocomplete/AppAutocomplete.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import AlertCircleIcon from "@/components/icons/AlertCircleIcon.vue";

const props = defineProps<{
  modelValue: string | undefined
  items: any[]
  min?: number
  max?: number
}>();
const emit = defineEmits(['update:modelValue', 'success']);
const isInvalid = ref(false);

const computedModelValue = computed({
  get: () => props.modelValue,
  set: (value: string) => {
    emit('update:modelValue', value)

    nextTick(() => {
      if(!isInvalid.value) {
        emit('success');
      }
    })
  }
});

const computedItems = computed(() => {
  return props.items.map(item => ({
    value: item,
    label: `${item}`
  }));
});

const errorMessage = computed(() => {
  if(props.min !== null && computedModelValue.value !== null) {
    if(props.min > computedModelValue.value) {
      return `Минимальное значение: ${props.min}`;
    }
  }

  if(props.max !== null && computedModelValue.value !== null) {
    if(props.max < computedModelValue.value) {
      return `Максимальное значение: ${props.max}`;
    }
  }

  return 'Неверное значение';
})
</script>

<template>
  <div
      :class="['scale-mark-autocomplete', {
        'scale-mark-autocomplete--invalid': isInvalid
      }]"
  >
    <app-autocomplete
        v-model="computedModelValue"
        :items="computedItems"
        hide-details
        only-numbers
        options-tab-disabled
        class="scale-mark-autocomplete__autocomplete"
        @invalid="(value) => isInvalid = value"
    />

    <template v-if="props.max">
      <span class="scale-mark-autocomplete__divider">/</span>
      {{ props.max }}
    </template>

    <app-tooltip
        left
        :disabled="!isInvalid"
        class="scale-mark-autocomplete__tooltip"
    >
      <template #activator>
        <alert-circle-icon
            :class="['cell-error__icon', {
              'cell-error__icon--hidden': !isInvalid
            }]"
        />
      </template>

      <span>
        {{ errorMessage }}
      </span>
    </app-tooltip>
  </div>
</template>

<style lang="scss">
.scale-mark-autocomplete {
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;

  &__autocomplete {
    width: 60px;

    .app-autocomplete {
      &__input {
        width: 100%;
        text-align: center;
        font-size: 12px;
      }
    }

    .app-select__option {
      text-align: center;
      justify-content: center;
    }
  }

  &--invalid {
    .scale-mark-autocomplete__autocomplete {
      border-color: $red;
    }
  }

  &__tooltip {
    .cell-error__icon {
      width: 20px;
      height: 20px;
      opacity: 1;
      visibility: visible;
      transition: opacity .3s;

      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .app-select {
    &__wrap {
      height: 100%;
      margin-right: 0;
    }

    &__arrow {
      display: none;
    }

    &__header {
      min-height: unset;
      height: 30px;
      padding: 0;
    }

    &__option {
      padding: 5px 8px;
      font-size: 12px;
      border-bottom: 1px solid #eee;
    }
  }
}
</style>