import {createRouter, createWebHistory} from 'vue-router'

import AuthIndex from '@/views/Auth/index.vue';
import FinishSignUp from '@/views/Auth/FinishSignUp.vue';
import SignIn from '@/views/Auth/SignIn.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import Impersonate from '@/views/Auth/Impersonate.vue';
import SelfRegistration from "@/views/Auth/SelfRegistration.vue";
import Cabinet from "@/views/Auth/Cabinet.vue";
import Agreements from '@/views/Auth/Agreements.vue';
import PageNotFound from "@/views/workspace/PageNotFound.vue";
import Security from "@/views/workspace/Security.vue";
import Support from "@/views/workspace/assessmentPlans/Support.vue";
import Plans from "@/views/workspace/assessmentPlans/Plans.vue";
import PlansArchive from "@/views/workspace/assessmentPlans/PlansArchive.vue";
import plan from "@/views/workspace/assessmentPlans/plan/index.vue";
import step1 from "@/views/workspace/assessmentPlans/plan/step1.vue";
import step2 from "@/views/workspace/assessmentPlans/plan/step2.vue";
import step3 from "@/views/workspace/assessmentPlans/plan/step3.vue";
import step4 from "@/views/workspace/assessmentPlans/plan/step4.vue";
import ResultsTable from "@/views/workspace/ResultsTable.vue";

import TestSingleSettings from "@/views/workspace/testLibrary/pages/tests/item/settings/index.vue";
import RespondentCabinet from "@/views/workspace/RespondentCabinet.vue";
import SetPermanentPassword from "@/views/Auth/SetPermanentPassword.vue";
import AuthTokenCache from "@/cache/auth-token.cache";
import CustomAssessment from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessment.vue";

const routes = [
    {
        path: '/',
        redirect: {
            name: 'AssessmentPlansList'
        },
    },
    {
        path: '/finish-signup',
        name: 'FinishSignup',
        component: FinishSignUp,
        meta: {
            auth: true,
            layout: "AuthMainLayout",
        }
    },
    {
        path: '/set-permanent-password',
        name: 'SetPermanentPassword',
        component: SetPermanentPassword,
        meta: {
            auth: true,
            layout: "AuthMainLayout",
        }
    },
    {
        path: '/auth/impersonate/:code',
        name: 'Impersonate',
        component: Impersonate,
        meta: {
            layout: "AuthMainLayout",
        },
    },
    {
        path: '/auth',
        name: 'Auth',
        component: AuthIndex,
        meta: {
            layout: "AuthMainLayout",
            guest: true,
        },
        children: [

            {
                path: 'signin',
                name: 'Signin',
                component: SignIn,
                beforeEnter: (to) => {
                    AuthTokenCache.clear();
                }
            },
            {
                path: 'forgot',
                name: 'Forgot',
                component: ForgotPassword,
            },

        ],
    },
    {
        path: '/agreements',
        name: 'Agreements',
        component: Agreements,
        meta: {
            layout: "AuthMainLayout",
            auth: true,
        }
    },

    {
        path: '/signup',
        name: 'SelfRegistration',
        component: SelfRegistration,
        meta: {
            layout: "AuthMainLayout",
            guest: true,
        },
    },


    /* SUPPORT */
    {
        path: '/support',
        name: 'Support',
        component: Support,
        meta: {
            layout: "WorkspaceMainLayout",
            auth: true,
            breadcrumbs: [
                {
                    value: 'Планы оценок', link: 'AssessmentPlansList'
                },
                {
                    value: 'Поддержка', link: ''
                },
            ],
        },
    },

    /* ASSESSMENT PLANS */
    {
        path: '/assessment-plans',
        name: 'AssessmentPlans',
        meta: {
            layout: "WorkspaceMainLayout",
            auth: true,
            title: 'Планы оценок',
        },
        redirect: {
            name: 'AssessmentPlansList'
        },
        children: [
            {
                path: '',
                name: 'AssessmentPlansList',
                component: Plans,
                meta: {
                    breadcrumbs: [
                        {value: 'Планы оценок', link: 'AssessmentPlansList'},
                    ],
                },
            },

            {
                path: 'archive',
                name: 'AssessmentPlansArchive',
                meta: {
                    breadcrumbs: [
                        {value: 'Планы оценок', link: 'AssessmentPlansList'},
                        {value: 'Архив'}
                    ],
                },
                component: PlansArchive,
            },

            {
                path: 'archive/:planUuid',
                name: 'AssessmentPlansArchiveSingle',
                meta: {
                    breadcrumbs: [
                        {
                            value: 'Планы оценок',
                            link: 'AssessmentPlansList',
                        },
                        {value: 'Архив', link: 'AssessmentPlansArchive'}
                    ],
                },
                component: plan,
            },

            {
                path: ':planUuid',
                name: 'ExpRespAssessmentPlanSingle',
                component: plan,
                meta: {
                    breadcrumbs: [
                        {value: 'Планы оценок', link: 'AssessmentPlansList'}
                    ],
                },
                children: [
                    {
                        path: 'tasks/:taskUuid',
                        name: 'RespondentTaskSingle',
                        component: CustomAssessment,
                    },

                    {
                        path: 'tasks/:taskUuid/respondents/:respondentUuid',
                        name: 'ExpertTaskSingle',
                        component: CustomAssessment,
                    },

                    {
                        path: 'step1',
                        name: 'AddEdit1',
                        meta: {
                            breadcrumbs: [
                                {value: 'Планы оценок', link: 'AssessmentPlansList'}
                            ],
                            role: 'admin'
                        },
                        component: step1,
                    },


                    {
                        path: 'step2',
                        name: 'AddEdit2',
                        meta: {
                            breadcrumbs: [
                                {value: 'Планы оценок', link: 'AssessmentPlansList'}
                            ],
                            role: 'admin'
                        },
                        component: step2,
                    },

                    {
                        path: 'step3',
                        name: 'AddEdit3',
                        meta: {
                            breadcrumbs: [
                                {value: 'Планы оценок', link: 'AssessmentPlansList'}
                            ],
                            role: 'admin'
                        },
                        component: step3,
                    },

                    {
                        path: 'step4',
                        name: 'AddEdit4',
                        meta: {
                            breadcrumbs: [
                                {value: 'Планы оценок', link: 'AssessmentPlansList'}
                            ],
                            role: 'admin'
                        },
                        component: step4,
                    },
                ],
            },

            {
                path: ':planUuid/results',
                name: 'AssessmentPlanSingleResults',
                meta: {
                    layout: "WorkspaceMainLayout",
                    breadcrumbs: [
                        {value: 'Планы оценок', link: 'AssessmentPlansList'},
                        {value: '', link: 'AddEdit1'},
                        {value: 'Таблица результатов'}
                    ],
                },
                component: ResultsTable,
                children: [
                    {
                        path: 'tasks/:taskUuid/respondents/:respondentUuid',
                        name: 'PlanSingleResultsExpertTaskSingle',
                        component: CustomAssessment,
                    },
                ]
            },

            {
                path: ':planUuid/respondents/:respondentUuid',
                name: 'RespondentCabinet',
                component: RespondentCabinet,
                meta: {
                    layout: "WorkspaceMainLayout",
                    breadcrumbs: [
                        {value: 'Планы оценок', link: 'AssessmentPlansList'},
                        {value: '', link: 'AddEdit1'},
                        {value: 'Таблица результатов', link: 'AssessmentPlanSingleResults'},
                    ],
                    role: 'admin'
                },
                children: [
                    {
                        path: 'tasks/:taskUuid',
                        name: 'RespondentCabinetTaskSingle',
                        component: CustomAssessment,
                    },
                ]
            },

            {
                path: ':uuid/settings',
                name: 'PlanSettings',
                meta: {
                    breadcrumbs: [
                        {value: 'Планы оценок', link: 'AssessmentPlansList'},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/assessmentPlans/plan/settings/Settings.vue'),
            },

            {
                path: ':uuid/settings/notifications',
                name: 'PlanSettingsNotifications',
                meta: {
                    breadcrumbs: [
                        {value: 'Планы оценок', link: 'AssessmentPlansList'},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/assessmentPlans/plan/settings/NotificationsSettings.vue'),
            },

            {
                path: ':uuid/exp-resp-correspondence',
                name: 'ExpRespCorrespondence',
                meta: {
                    breadcrumbs: [
                        {value: 'Планы оценок', link: 'AssessmentPlansList',},
                        {value: 'Редактирование', link: '',},
                        {value: 'Шаг 4', link: 'AddEdit4',},
                        {value: 'Соответствие экспертов и респондентов', link: '',},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/assessmentPlans/plan/expRespCorrespondence.vue'),
            },

            {
                path: 'results',
                name: 'Results',
                meta: {
                    title: 'Таблица результатов'
                },
                component: () => import('@/views/workspace/assessmentPlans/Results.vue'),
            },
        ],
    },

    /* TEST LIBRARY */
    {
        path: '/test-library',
        name: 'TestLibrary',
        redirect: {
            name: 'TestLibraryTestsIndex'
        },
        meta: {
            auth: true,
            title: 'Тесты',
            layout: "WorkspaceMainLayout",
        },
        children: [
            {
                path: 'tests',
                name: 'TestLibraryTests',
                meta: {
                    title: 'Библиотека тестов',
                    breadcrumbs: [
                        {value: 'Тесты', link: 'TestLibraryTestsIndex',},
                        {value: 'Библиотека', link: '',},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/testLibrary/pages/tests/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'TestLibraryTestsIndex',
                        meta: {
                            role: 'admin'
                        },
                        component: () => import('@/views/workspace/testLibrary/pages/tests/list.vue'),
                    },
                    {
                        path: 'list',
                        name: 'TestLibraryTestsList',
                        meta: {
                            role: 'admin'
                        },
                        component: () => import('@/views/workspace/testLibrary/pages/tests/list.vue'),
                    },
                    {
                        path: ':uuid',
                        name: 'TestLibraryTestsItem',
                        meta: {
                            role: 'admin'
                        },
                        component: () => import('@/views/workspace/testLibrary/pages/tests/item/index.vue'),
                    },
                    {
                        path: ':uuid/settings',
                        name: 'TestLibraryTestsItemSettings',
                        meta: {
                            breadcrumbs: [
                                {value: 'Тесты', link: 'TestLibraryTestsIndex',},
                                {value: 'Настройка теста', link: ''}
                            ],
                            title: 'Настройки теста',
                            role: 'admin'
                        },
                        component: TestSingleSettings,
                    },
                    {
                        path: ':uuid/settings/reports/:uuid_variant/block/create',
                        name: 'TestSettingsCreateBlock',
                        meta: {
                            breadcrumbs: [
                                {value: 'Библиотека тестов', link: 'TestLibraryTestsIndex',},
                                {value: 'Тесты', link: 'TestLibraryTestsIndex'}
                            ],
                            role: 'admin'
                        },
                        component: () => import('@/views/workspace/testLibrary/pages/tests/item/settings/block/index.vue'),
                    },
                    {
                        path: ':uuid/settings/reports/:uuid_variant/block/:uuid_block',
                        name: 'TestSettingsEditBlock',
                        meta: {
                            breadcrumbs: [
                                {value: 'Библиотека тестов', link: 'TestLibraryTestsIndex',},
                                {value: 'Тесты', link: 'TestLibraryTestsIndex',},
                            ],
                            role: 'admin'
                        },
                        component: () => import('@/views/workspace/testLibrary/pages/tests/item/settings/block/index.vue'),
                    },
                ],
            },
            {
                path: 'tests-pro',
                name: 'TestLibraryTestsPro',
                meta: {
                    title: 'Собственные тесты',
                    breadcrumbs: [
                        {value: 'Тесты', link: 'TestLibraryTestsIndex',},
                        {value: 'Собственные', link: '',},
                    ],
                },
                component: () => import('@/views/workspace/testLibrary/pages/tests/list.vue'),
            },
            {
                path: 'results',
                name: 'TestLibraryResults',
                meta: {
                    title: 'Результаты',
                    breadcrumbs: [
                        {value: 'Тесты', link: 'TestLibraryTestsIndex',},
                        {value: 'Результаты', link: '',},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/testLibrary/pages/results.vue'),
            },
            {
                path: 'sessions',
                name: 'TestLibrarySessions',
                meta: {
                    title: 'Сеансы',
                    breadcrumbs: [
                        {value: 'Тесты', link: 'TestLibraryTestsIndex',},
                        {value: 'Сеансы', link: '',},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/testLibrary/pages/sessions.vue'),
            },
            /*{
                path: 'trash',
                name: 'TestLibraryTrash',
                meta: {
                    title: 'Корзина',
                    breadcrumbs: [
                        { value: 'Библиотека тестов', link: 'TestLibraryTestsIndex', },
                        { value: 'Корзина', link: '', },
                    ],
                },
                component: () => import('@/views/workspace/testLibrary/pages/trash/index.vue'),
            },*/
        ],
    },

    /* ADMINISTRATION */
    {
        path: '/administration',
        meta: {
            layout: "WorkspaceMainLayout",
            title: 'Администрирование',
            auth: true,
            role: 'admin'
        },
        children: [
            {
                path: '',
                component: () => import('@/views/workspace/administration/pages/admins/index.vue'),
                meta: {
                    role: 'admin'
                },
                children: [
                    {
                        path: 'admins',
                        name: 'AdminsList',
                        meta: {
                            breadcrumbs: [
                                {value: 'Администрирование', link: 'AdminsList',},
                                {value: 'Администраторы', link: '',},
                            ],
                        },
                        component: () => import('@/views/workspace/administration/pages/admins/list.vue'),
                    },

                    {
                        path: 'create',
                        name: 'CreateAdmin',
                        meta: {
                            breadcrumbs: [
                                {value: 'Администрирование', link: 'AdminsList',},
                                {value: 'Администраторы', link: 'AdminsList',},
                                {createAdmin: 'CreateAdmin', value: 'Создать', link: '',},

                            ],
                        },
                        component: () => import('@/views/workspace/administration/pages/admins/create.vue'),
                    },

                    {
                        path: ':id',
                        name: 'Admin',
                        meta: {
                            breadcrumbs: [
                                {value: 'Администрирование', link: 'AdminsList',},
                                {value: 'Администраторы', link: 'AdminsList',}
                            ],
                        },
                        component: () => import('@/views/workspace/administration/pages/admins/id.vue'),
                    },
                ],
            },

            {
                path: 'billing',
                name: 'Billing',
                meta: {
                    breadcrumbs: [
                        {value: 'Администрирование', link: 'AdminsList',},
                        {value: 'Биллинг', link: '',},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/administration/pages/billing/index.vue'),
            },

            {
                path: 'events',
                name: 'Events',
                meta: {
                    breadcrumbs: [
                        {value: 'Администрирование', link: 'AdminsList',},
                        {value: 'События', link: '',},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/administration/pages/events/index.vue'),
            },


            {
                path: 'users',
                name: 'Users',
                component: () => import('@/views/workspace/administration/pages/users/index.vue'),
                redirect: {
                    name: 'UsersList'
                },
                children: [
                    {
                        path: '',
                        name: 'UsersList',
                        meta: {
                            breadcrumbs: [
                                {value: 'Администрирование', link: 'AdminsList',},
                                {value: 'Пользователи', link: '',},
                            ],
                            role: 'admin'
                        },
                        component: () => import('@/views/workspace/administration/pages/users/list.vue'),
                    },

                    {
                        path: 'create',
                        name: 'CreateUser',
                        meta: {
                            breadcrumbs: [
                                {value: 'Администрирование', link: 'AdminsList',},
                                {value: 'Пользователи', link: 'UsersList',},
                                {value: 'Создать'},
                            ],
                            role: 'admin'
                        },
                        component: () => import('@/views/workspace/administration/pages/users/create.vue'),
                    },

                    {
                        path: ':id',
                        name: 'UserSingle',
                        meta: {
                            breadcrumbs: [
                                {value: 'Администрирование', link: 'AdminsList'},
                                {value: 'Пользователи', link: 'UsersList'}
                            ],
                            role: 'admin'
                        },
                        component: () => import('@/views/workspace/administration/pages/users/id.vue'),
                    },
                ],
            },

            {
                path: 'settings',
                name: 'SettingsIndex',
                meta: {
                    breadcrumbs: [
                        {value: 'Администрирование', link: 'AdminsList',},
                        {value: 'Настройка', link: '',},
                    ],
                    role: 'admin'
                },
                component: () => import('@/views/workspace/administration/pages/settings/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'Settings',
                        meta: {
                            breadcrumbs: [
                                {value: 'Администрирование', link: 'AdminsList',},
                                {value: 'Настройки', link: '',},
                            ],
                        },
                        component: () => import('@/views/workspace/administration/pages/settings/index.vue'),
                    },

                ],
            }
        ],
    },

    {
        path: '/cabinet',
        name: 'UserCabinet',
        meta: {
            layout: "WorkspaceMainLayout",
            auth: true,
            title: 'Личные данные',
        },
        component: Cabinet
    },

    {
        path: '/security',
        name: 'UserSecurity',
        meta: {
            layout: "WorkspaceMainLayout",
            auth: true,
            title: 'Безопасность',
        },
        component: Security
    },

    {
        path: "/:pathMatch(.*)*",
        meta: {
            layout: "WorkspaceMainLayout",
        },
        component: PageNotFound
    }
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authTokenExists = AuthTokenCache.exists();

    if (to.meta.auth && !authTokenExists) {
        return next({
            name: 'Signin'
        })
    }


    if (to.meta.guest && authTokenExists && to.name != 'Signin') {
        return next("/");
    }

    next()
});

export default router