<template>
  <div>
    <expert-review-table />

    <router-view />
  </div>
</template>

<script>
import ExpertReviewTable from "@/components/workspace/ExpertReviewTable.vue";
import { mapActions } from "vuex";
import usePlansStore from "@/store/plans.store";
import {storeToRefs} from "pinia";

export default {
  name: "ResultsTable",
  components: { ExpertReviewTable },
  setup() {
    const route = useRoute();
    const plansStore = usePlansStore();
    const {plan} = storeToRefs(plansStore);

    async function getPlan() {
      await plansStore.getSingle(route.params.planUuid);
    }

    return {
      getPlan,
      plan
    }
  },
  methods: {
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),

    async initBreadcrumbs() {
      const breadcrumbs = this.$route.meta.breadcrumbs;
      breadcrumbs.forEach(item => {
        if(item.link === 'AddEdit1') {
          item.value = this.plan.name;
          item.params = {
            uuid: this.$route.params.planUuid
          };
        }

        return item;
      })

     await this.setBreadcrumbs(breadcrumbs)
    }
  },
  async mounted() {
    await this.getPlan();
    await this.initBreadcrumbs();
  }
}
</script>