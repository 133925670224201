import api from "@/network";
import { respExpPlanIndexInputAdapter } from '@/adapters/assessmentPlans/expRespPlans.adapter';

export default {
  state: {
    plans: [],
    adminPlans: [],
    expRespArchivePlans: [],
    expRespPlans: [],
    expRespActivePlans: {
      plans: []
    },
    expRespFinishedPlans: {
      plans: []
    },
    isLoading: false
  },
  getters: {
    archivePlansRows(state) {
      return [
        {
          title: '',
          plans: state.expRespArchivePlans
        }
      ]
    },
    assessmentPlans(state) {
      return state.plans;
    },
    plansRows(state) {
      return [
        {
          title: 'Актуальные планы оценок',
          plans: state.expRespActivePlans
        },
        {
          title: 'Завершенные планы оценок',
          plans: state.expRespFinishedPlans
        }
      ]
    },
  },

  actions: {
    /* FETCHERS */
    async fetchArchivePlans({ commit }, payload = {}) {
      const response = await api.get(
        'plans/mode/archive',
        {
          params: {
            page: payload.page ?? 1,
            per_page: payload.per_page ?? 30,
            settings: payload.settings,
            result: payload.result,
          },
        }
      );

      commit('updateArchiveExpRespPlans', response.data);
    },
    async fetchPlans({ commit, rootGetters }, payload = {}) {
      commit("setLoading", true)

      const response = await api.get(
          'plans',
          {
            params: {
              page: payload.page ?? 1,
              per_page: payload.per_page,
              settings: payload.settings,
              result: payload.result,
            },
          }
      );

      commit('updatePlans', response.data.data);
      commit("setLoading", false)

      return response;
    },

    async fetchActivePlans({ commit }, payload = {}) {
      commit("setLoading", true)

      const response = await api.get(
        'plans',
        {
          params: {
            page: payload.page ?? 1,
            per_page: payload.per_page ?? 30,
            settings: payload.settings,
            result: payload.result,
          },
        }
      );

      commit('updateExpRespActivePlans', response.data);
      commit("setLoading", false)
    },

    async fetchCompletedPlans({ commit }, payload = {}) {
      commit("setLoading", true)
      const response = await api.get(
        'plans/mode/completed',
        {
          params: {
            page: payload.page ?? 1,
            per_page: payload.per_page ?? 30,
            settings: payload.settings,
            result: payload.result,
          },
        }
      );

      commit('updateExpRespFinishedPlans', response.data);
      commit("setLoading", false)
    },

    async addTags({ commit }, payload = {}) {
      return await api.put('plans/tags-attach', payload.tags);
    },

    async resetPlans({ commit }) {
      commit('updatePlans', []);
    },

    async deletePlans({ commit }, payload = {}) {
      return await api.delete('plans/delete', { data: payload.data });
    },

    async replicatePlans({ commit }, payload = {}) {
      return await api.post('plans/replicate', { data: payload.data });
    },

    async switchPlans({ commit }, payload) {
      if (!payload.plans.length) return;

      payload.plans = payload.plans.map((plan) => plan.uuid);
      return await api.put('plans/active', payload);
    },
    async addToArchive({ commit }, payload = {}) {
      return await api.put('plans/archive', payload.body);
    },
    async restorePlan({ commit }, payload = {}) {
      return await api.put('plans/unarchive', payload.body);
    },
  },

  mutations: {
    updateArchiveExpRespPlans(state, data) {
      state.expRespArchivePlans = {
        name: 'archive',
        plans: respExpPlanIndexInputAdapter(data.data),
        meta: data.meta
      };
    },
    updateExpRespActivePlans(state, data) {
      state.expRespActivePlans.name = 'active'
      state.expRespActivePlans.meta = data.meta

      if (state.expRespActivePlans.plans.length && data.meta.current_page !== 1) state.expRespActivePlans.plans = state.expRespActivePlans.plans.concat(respExpPlanIndexInputAdapter(data.data))
      else state.expRespActivePlans.plans = respExpPlanIndexInputAdapter(data.data)

      state.isLoading = false;
    },
    updateExpRespFinishedPlans(state, data) {
      state.expRespFinishedPlans.name = 'completed'
      state.expRespFinishedPlans.meta = data.meta

      if (state.expRespFinishedPlans.plans.length && data.meta.current_page !== 1) state.expRespFinishedPlans.plans = state.expRespFinishedPlans.plans.concat(respExpPlanIndexInputAdapter(data.data))
      else state.expRespFinishedPlans.plans = respExpPlanIndexInputAdapter(data.data)
    },
    clearExpRespPlans(state) {
      if (state.expRespFinishedPlans.plans) state.expRespFinishedPlans.plans = [];
      if (state.expRespActivePlans.plans) state.expRespActivePlans.plans = [];
    },
    async updatePlans(state, plans) {
      // .filter(plan => !plan.archive)
      if (this.getters.authUser.role.slug === 'admin') {
        return state.adminPlans = plans
          .map((plan) => {
            return {
              ...plan,
              selected: false,
              delLoader: false,
            };
          })
          .filter((plan) => plan.name);
      }

      state.plans = plans;
    },

    setLoading(state, payload) {
      state.isLoading = payload
    }
  },
}