<template>
  <div
      ref="item"
      :class="['expander__item', {
        'expander__item_active': isActive,
        'expander__item_disabled': disabled
      }]"
  >
    <div
        v-show="hasHeaderSlot"
        class="expander__item__header"
        tabindex="0"
        @click="toggle()"
    >
      <div
          v-if="isSlotExist('prependHeader')"
          class="expander__item__prepend"
      >
        <slot name="prependHeader"/>
      </div>
      <div
          class="expander__item__title"
          ref="headerSlot"
      >
        <slot name="header"/>
      </div>
      <button
          class="expander__item__arrow-btn"
          v-show="!disabled"
      >
        <arrow-down-icon/>
      </button>
      <div
          class="expander__item__append"
          v-if="isSlotExist('appendHeader')"
      >
        <slot name="appendHeader"/>
      </div>
    </div>
    <slide-in-out-animation v-model="isActive">
      <div class="expander__item__body">
        <slot name="content"/>
      </div>
    </slide-in-out-animation>
  </div>
</template>
<script>
import mtSvg from "@/components/UI/mtSvg/index.vue";
import SlideInOutAnimation from '@/components/UI/mtSlideInOutAnimation/index.vue';
import ArrowDownIcon from "@/components/icons/ArrowDownIcon.vue";

export default {
  name: 'mtExpanderItem',
  components: {
    ArrowDownIcon,
    SlideInOutAnimation,
    mtSvg
  },
  data: () => ({
    isActive: true,
    hasHeaderSlot: true
  }),
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return;

      this.isActive = !this.isActive;
    },

    isSlotExist(item) {
      return this.$slots[item]
    },
  },
  created() {
    this.isActive = this.state;
  },
  mounted() {
    this.hasHeaderSlot = !!this.$refs.headerSlot?.innerText;
  }
}
</script>
<style lang="scss" src="./mtExpander.scss"/>