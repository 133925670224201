<template>
  <div class="app-form-block">
    <div class="mt-time-interval-field">
      <div class="mt-time-interval-field--container">
        <div class="mt-time-interval-field--container__date">
          <app-date-time-picker
            v-model="date.from"
            style="margin-bottom: 0"
            :class="{ 'app-input_error': hasError.from }"
            :format="computedFormat"
            :type="withTime?'dateTime':'date'"
            :max-date="date.to"
            @update:modelValue="emitData"
          >
            <template #label> Период доступа с</template>
          </app-date-time-picker>
        </div>
        <div class="mt-time-interval-field--container__date">
          <app-date-time-picker
            v-model="date.to"
            style="margin-bottom: 0"
            :class="{ 'app-input_error': hasError.to }"
            :format="computedFormat"
            :type="withTime?'dateTime':'date'"
            :min-date="date.from"
            @update:modelValue="emitData"
          >
            <template #label> Период доступа по</template>
          </app-date-time-picker>
        </div>
      </div>
      <div
        class="edit-general-info-block__error-message"
        style="margin-top: 5px"
      >
        <template v-if="hasError.from">
          Дата начала не должна быть позже даты окончания
        </template>
        <template v-else-if="hasError.to">
          Дата окончания не должна быть раньше даты начала
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MtSvg from "@/components/UI/mtSvg/index.vue";
import { format, parse, startOfDay } from "date-fns";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import AppDateTimePicker from "../UI/AppDateTimePicker/AppDateTimePicker.vue";

export default {
  components: {
    AppInput,
    MtSvg,
    AppDateTimePicker
  },
  props: {
    preloader: {
      type: Boolean,
      default: false
    },
    dateFrom: {
      type: String,
      default: ""
    },
    dateTo: {
      type: String,
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false
    },
    withTime: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    datePopoverVisibility: false,
    field: "",
    hasError: {
      from: false,
      to: false
    },
    date: {
      from: "",
      to: ""
    }
  }),
  watch: {
    dateFrom: {
      handler(newVal) {
        this.date.from = this.formatDate(newVal);
      },
      immediate: true
    },
    dateTo: {
      handler(newVal) {

        this.date.to = this.formatDate(newVal);
      },
      immediate: true
    }
  },
  computed: {
    computedFormat() {
      return this.withTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";
    }
  },
  methods: {
    formatDate(dateStr) {
      if (!dateStr) return "";
      return format(new Date(dateStr), this.computedFormat);
    },
    validateDates() {
      if (this.date.from && this.date.to) {
        this.hasError.from = new Date(this.date.from) > new Date(this.date.to);
        this.hasError.to = new Date(this.date.to) < new Date(this.date.from);
      } else {
        this.hasError.from = false;
        this.hasError.to = false;
      }
    },
    emitData() {
      const dateFrom = this.date.from !== null && this.date.from !== "" ? format(parse(this.date.from, this.computedFormat, new Date()), this.withTime ? "yyyy-MM-dd HH:mm:00" : "yyyy-MM-dd") : null;
      const dateTo = this.date.to !== null && this.date.to !== "" ? format(parse(this.date.to, this.computedFormat, new Date()), this.withTime ? "yyyy-MM-dd HH:mm:00" : "yyyy-MM-dd") : null;

      const date = {
        from: dateFrom,
        to: dateTo
      };

      this.$emit("update-date", date);
    }
  },
  mounted() {
    this.validateDates();
  }
};
</script>

<style lang="scss">
.mt-time-interval-field {
  width: 100%;

  &--title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #7c869d;
    margin-bottom: 10px;
  }

  &--container {
    display: flex;
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    margin: 0 -5px;

    &__date {
      margin: 0 5px;
      flex: 0 1 100%;

      &__input {
        height: 56px;
        width: 100%;
        padding: 15px 15px 15px 20px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #b4b4b4;

        &_has-error {
          background-color: rgba(255, 0, 0, 0.3);
        }
      }

      &-title {
        color: #7c869d;
      }

      &-value {
        color: #2a4365;
        min-width: 75px;
        height: 22px;
        width: 100%;
        margin-left: 5px;
      }

      &-icon {
        background-size: cover;
        background: url('../../../src/assets/img/svg/assessment-plans/calendar.svg') no-repeat center;
        min-width: 16px;
        height: 16px;
        margin-left: 8px;
      }

      &-close-icon {
        margin-left: 8px;
      }

      &__error-text.edit-general-info-block__error-message {
        height: unset !important;
      }
    }

    @include max-xs {
      flex-wrap: wrap;
      height: unset;

      &__date {
        width: 100%;
        margin: 0;

        & + & {
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
