<script
    setup
    lang="ts"
>

</script>

<template>
  <svg
      fill="currentColor"
      width="24px"
      height="24px"
      viewBox="0 0 36 36"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M6,6H22v4h2V6a2,2,0,0,0-2-2H6A2,2,0,0,0,4,6V22a2,2,0,0,0,2,2h4V22H6Z"
        class="clr-i-outline clr-i-outline-path-1"
    />
    <path
        d="M30,12H14a2,2,0,0,0-2,2V30a2,2,0,0,0,2,2H30a2,2,0,0,0,2-2V14A2,2,0,0,0,30,12Zm0,18H14V14H30Z"
        class="clr-i-outline clr-i-outline-path-2"
    />
    <polygon
        points="21 28 23 28 23 23 28 23 28 21 23 21 23 16 21 16 21 21 16 21 16 23 21 23 21 28"
        class="clr-i-outline clr-i-outline-path-3"
    />
    <rect
        x="0"
        y="0"
        width="36"
        height="36"
        fill-opacity="0"
    />
  </svg>
</template>

<style
    scoped
    lang="scss"
>

</style>