<template>
  <mt-dialog
      :opened-by-default="true"
      size="xl"
      class="custom-assessment"
      @close="onClose()"
  >
    <custom-assessment-preloader v-if="!isLoaded" />

    <template v-else-if="task">
      <div class="custom-assessment__loader">
        <mt-loading-animation-linear
            v-if="isLoaded && isLoading"
            class="custom-assessment__loader__linear"
        />
      </div>

      <div class="custom-assessment__header">
        <app-tooltip v-if="!isRespondent">
          <template #activator>
            <mt-button
                color="light-green"
                icon
                class="custom-assessment__expander__header__btn"
                :disabled="!hasPrevTask"
                @click.stop="() => changeTask('prev')"
            >
              <chevron-left-icon :doubled="prevTaskIsOtherRespondent" />
            </mt-button>
          </template>

          {{
            !hasPrevTask ? 'Это самое первое задание' : prevTaskIsOtherRespondent ? 'Предыдущий респондент' : 'Предыдущее задание'
          }}
        </app-tooltip>
        <h3 class="custom-assessment__title">{{ task?.name }}</h3>
        <app-tooltip v-if="!isRespondent">
          <template #activator>
            <mt-button
                color="light-green"
                icon
                class="custom-assessment__expander__header__btn"
                :disabled="!hasNextTask"
                @click.stop="() => changeTask('next')"
            >
              <chevron-left-icon
                  :doubled="nextTaskIsOtherRespondent"
                  style="transform: rotate(0);"
              />
            </mt-button>
          </template>

          {{
            !hasNextTask ? 'Это последнее задание' : nextTaskIsOtherRespondent ? 'Следующий респондент' : 'Следующее задание'
          }}
        </app-tooltip>
      </div>
      <div class="custom-assessment__container">
        <div class="custom-assessment__sidebar">
          <div class="custom-assessment__details">
            <div
                v-if="!isRespondent"
                class="custom-assessment__respondent"
            >
              <div class="custom-assessment__respondent__user-icon">
                <img
                    src="@/assets/img/svg/user.svg"
                    alt=""
                />
              </div>

              <h2 class="custom-assessment__respondent__title">
                Респондент:
                <br />
                <span>{{respondent?.full_name}}</span>
              </h2>
            </div>

            <div
                class="custom-assessment__details__item"
                v-for="(item, index) in details"
                :key="index"
            >
              <h5 class="custom-assessment__details__item__label">{{ item.label }}</h5>
              <span class="custom-assessment__details__item__value"> {{ item.value ?? '-' }}</span>
            </div>
          </div>
          <div class="custom-assessment__description">
            <h5 class="custom-assessment__sidebar__label">Описание</h5>
            <p class="custom-assessment__description__text">
              <template v-if="taskDescription">
                {{ taskDescription }}
              </template>
              <i v-else>Описание отсутствует</i>
            </p>
          </div>

          <div class="custom-assessment__files">
            <h5 class="custom-assessment__sidebar__label">Прикреплённые файлы</h5>
            <app-uploaded-file
                v-if="taskFiles?.length"
                v-for="(file, index) in taskFiles"
                :key="index"
                :file
            />
            <p
                v-else
                class="custom-assessment__description__text"
            >
              <i>Файлы отсутствуют</i>
            </p>
          </div>

          <div class="custom-assessment__files">
            <h5 class="custom-assessment__sidebar__label">Файлы с чата</h5>

            <app-uploaded-file
                v-if="chatFiles?.length"
                v-for="(file, index) in chatFiles"
                :key="index"
                :file
                name-key="fileName"
                url-key="originalUrl"
            >
              <template #prepend>
                {{ file.user?.fullName }}
              </template>
            </app-uploaded-file>
            <p
                v-else
                class="custom-assessment__description__text"
            >
              <i>Файлы отсутствуют</i>
            </p>
          </div>
        </div>
        <div class="custom-assessment__main">
          <app-tabs-nav
              v-model="activeTab"
              :items="tabs"
              class="custom-assessment__tabs-nav"
              has-indicator
              :pt="{
                item: {
                  class: 'custom-assessment__tabs-nav__item'
                }
              }"
          >
            <template #item.chat="{item}">
              {{item.title}} ({{ chatMessagesCounter }})
            </template>
          </app-tabs-nav>

          <app-tabs
              v-model="activeTab"
              :items="tabs"
              :pt="{
                item: {
                  class: 'custom-assessment__tabs__item'
                }
              }"
              class="custom-assessment__tabs"
          >
            <template #item.mark="{item}">
              <div class="custom-assessment__form">
                <custom-assessment-marks
                    :scales
                    :results
                    :readonly="isRespondent"
                    @setMark="setMark"
                />
              </div>
            </template>
            <template #item.chat="{item}">
              <custom-assessment-chat
                  ref="chatRef"
                  :session-uuid="sessionUuid"
                  :key="taskUuid"
                  @update-count="(count) => chatMessagesCounter = count"
              />
            </template>
          </app-tabs>
        </div>
      </div>
    </template>
  </mt-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import mtButton from '@/components/UI/mtButton/index.vue';
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import AppCheckbox from "@/components/UI/AppCheckbox/AppCheckbox.vue";
import ScaleMarkRange from "@/components/workspace/ScaleMarkRange.vue";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import CustomAssessmentPreloader
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessmentPreloader.vue";
import MtExpander from "@/components/UI/mtExpander/mtExpander.vue";
import fileDownloadCard from "@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import MtExpanderItem from "@/components/UI/mtExpander/mtExpanderItem.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import CustomAssessmentChat
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessmentChat.vue";
import MtLinearProgress from "@/components/UI/mtLinearProgress/mtLinearProgress.vue";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import AppTabsNav from "@/components/UI/AppTabs/AppTabsNav.vue";
import AppTabs from "@/components/UI/AppTabs/AppTabs.vue";
import StarIcon from "@/components/icons/StarIcon.vue";
import AppDataTable from "@/components/UI/AppDataTable/AppDataTable.vue";
import AppFilesUploader from "@/components/composite/AppFilesUploader.vue";
import MtFileView from "@/components/UI/mtFileView.vue";
import ScaleMarkAutocomplete from "@/components/workspace/ScaleMarkAutocomplete.vue";
import AlertCircleIcon from "@/components/icons/AlertCircleIcon.vue";
import AppUploadedFile from "@/components/UI/AppUploadedFile.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import usePlansStore from "@/store/plans.store";
import {storeToRefs} from "pinia";
import useRespondentsStore from "@/store/respondents.store";
import CustomAssessmentMarks
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessmentMarks.vue";
import customAssessmentApi from "@/api/custom-assessment.api";

export default {
  name: "expertCustomAssessment",
  components: {
    CustomAssessmentMarks,
    InfoIcon,
    AppUploadedFile,
    AlertCircleIcon,
    ScaleMarkAutocomplete,
    MtFileView,
    AppFilesUploader,
    AppDataTable,
    StarIcon,
    AppTabs,
    AppTabsNav,
    MtLoadingAnimationLinear,
    MtLinearProgress,
    CustomAssessmentChat,
    AppTooltip,
    MtExpanderItem,
    ChevronLeftIcon,
    fileDownloadCard,
    MtExpander,
    CustomAssessmentPreloader,
    MtDialog,
    ScaleMarkRange,
    AppCheckbox,
    AppSelect,
    mtButton
  },
  data() {
    return {
      activeTab: 'mark',
      tabs: [
        {
          title: 'Оценка',
          value: 'mark'
        },
        {
          title: 'Чат',
          value: 'chat'
        }
      ],
      isLoaded: true,
      isLoading: false,
      prevTask: false,
      nextTask: false,
      haveMarksChanged: false,
      sessionUuid: null,
      chatMessagesCounter: 0
    }
  },
  setup() {
    const plansStore = usePlansStore();
    const {plan} = storeToRefs(plansStore);

    const respondentsStore = useRespondentsStore();
    const {respondents} = storeToRefs(respondentsStore);

    return {
      assessmentPlan: plan,
      respondents,
      respondentsStore
    }
  },
  computed: {
    ...mapState('assessmentPlans/plan/task', ['messages']),
    ...mapGetters(['isExpert', 'isAdmin', 'isRespondent', 'authUser']),

    details() {
      const items = [
        {
          label: 'План оценки',
          value: this.assessmentPlan.name
        },
        {
          label: 'Автор оценки',
          value: this.respondentTask?.expert
        },
        {
          label: 'Дата активности',
          value: this.activeDateText
        }
      ]

      return items;
    },

    taskDescription() {
      if (this.isRespondent) {
        return this.task.description
      }

      return this.task.description_expert
    },

    taskFiles() {
      if (this.isRespondent) {
        return this.task.files
      }

      return this.task.expert_files
    },

    activeDateText() {
      if (!this.task) return '';
      if (this.task.date_from && this.task.date_to) {
        return `с ${this.task.date_from} по ${this.task.date_to}`;
      } else if (this.task.date_from) {
        return `с ${this.task.date_from}`;
      } else if (this.task.date_to) {
        return `по ${this.task.date_to}`;
      }
    },

    planUuid() {
      return this.$route.params.planUuid;
    },

    taskUuid() {
      return this.$route.params.taskUuid;
    },

    task() {
      const tests = this.assessmentPlan.tests ?? this.assessmentPlan.structure;

      return tests?.find((item) => item.uuid === this.taskUuid) ?? null;
    },

    respondentTask() {
      return this.respondent?.tests.find((item) => item.uuid === this.taskUuid);
    },

    scales() {
      if (this.respondentTask?.scales) return this.respondentTask?.scales ? JSON.parse(JSON.stringify(this.respondentTask?.scales)) : [];
      else if (this.task?.scales) {
        return this.task?.scales ? JSON.parse(JSON.stringify(this.task?.scales)) : [];
      } else {
        return [];
      }
    },

    results() {
      if (this.respondentTask) {
        return {
          current: this.respondentTask.current,
          total: this.respondentTask.total,
          current_with_weight: this.respondentTask.current_with_weight,
          total_with_weight: this.respondentTask.total_with_weight
        }
      } else if (this.task) {
        return {
          current: this.task.current,
          total: this.task.total,
          current_with_weight: this.task.current_with_weight,
          total_with_weight: this.task.total_with_weight
        }
      } else {
        return {
          current: 0,
          total: 0,
          current_with_weight: 0,
          total_with_weight: 0
        }
      }
    },

    respondentUuid() {
      if (this.isRespondent) {
        return this.authUser.uuid;
      }

      return this.$route.params.respondentUuid;
    },

    respondent() {
      if (!this.respondentUuid) return null;
      return this.respondents.find((item) => item.uuid === this.respondentUuid);
    },

    hasNextTask() {
      if (!this.respondent) return false;

      const currentTaskIndex = this.respondent.tests.findIndex((item) => item.uuid === this.taskUuid);
      const currentRespondentIndex = this.respondents.findIndex((item) => item.uuid === this.respondentUuid);
      const result = this.getNextTask(currentTaskIndex, currentRespondentIndex);

      if (result) {
        this.nextTask = result;
      } else {
        this.nextTask = false;
      }

      return this.nextTask;
    },

    hasPrevTask() {
      if (!this.respondent) return false;

      const tests = this.assessmentPlan.tests ?? this.assessmentPlan.structure;
      const currentTaskIndex = tests.findIndex((item) => item.uuid === this.taskUuid);
      const currentRespondentIndex = this.respondents.findIndex((item) => item.uuid === this.respondentUuid);
      const result = this.getPrevTask(currentTaskIndex, currentRespondentIndex);

      if (result) {
        this.prevTask = result;
      } else {
        this.prevTask = false;
      }

      return this.prevTask;
    },

    nextTaskIsOtherRespondent() {
      if (!this.hasNextTask) return false;
      return this.hasNextTask.respondentUuid !== this.respondentUuid;
    },

    prevTaskIsOtherRespondent() {
      if (!this.hasPrevTask) return false;
      return this.hasPrevTask.respondentUuid !== this.respondentUuid;
    },

    chatFiles() {
      return this.messages.reduce((acc, item) => {
        const modifiedFiles = item.files.map(i => {
          i.user = item.user;
          return i;
        })
        acc.push(...modifiedFiles)
        return acc
      }, []);
    }
  },
  methods: {
    ...mapMutations('assessmentPlans/plan/task', ['updateMark', 'updateMarksChanged']),
    ...mapActions('assessmentPlans/plan', ['getPlanProgress']),
    ...mapMutations('assessmentPlans/plan/task', ['setChatMessages']),

    onClose() {
      if (!this.isAdmin) {
        this.getPlanProgress({
          uuid: this.planUuid,
        });
      }

      if (this.haveMarksChanged) {
        this.updateMarksChanged({
          testUuid: this.taskUuid,
          respondentUuid: this.respondentUuid
        });
        this.haveMarksChanged = false;
      }

      let routeName;
      switch (this.$route.name) {
        case "ExpertTaskSingle":
          routeName = 'ExpRespAssessmentPlanSingle';
          break;
        case "RespondentTaskSingle":
          routeName = 'ExpRespAssessmentPlanSingle';
          break;
        case "RespondentCabinetTaskSingle":
          routeName = 'RespondentCabinet';
          break;
        default:
          routeName = 'AssessmentPlanSingleResults';
      }

      this.$router.replace({
        name: routeName,
        params: {
          uuid: this.planUuid
        }
      })
    },

    async setMark(scale) {
      const params = {
        respondentUuid: this.respondentUuid,
        planUuid: this.planUuid,
        taskUuid: this.taskUuid,
        sessionUuid: this.sessionUuid,
        scale
      }

      this.respondentsStore.setMarkToRespondentTaskScale(params);
    },

    loadNextTask() {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          taskUuid: this.nextTask.uuid,
          respondentUuid: this.nextTask.respondentUuid
        }
      })
    },

    loadPrevTask() {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          taskUuid: this.prevTask.uuid,
          respondentUuid: this.prevTask.respondentUuid
        }
      })
    },

    getPrevTask(taskIndex, respondentIndex) {
      let result = false;

      if (taskIndex === 0) {
        const prevRespondent = this.respondents[respondentIndex - 1];
        if (prevRespondent) {
          result = this.getPrevTask(prevRespondent.tests.length, respondentIndex - 1);
        } else {
          result = false;
        }
      } else {
        const prevTask = this.respondents[respondentIndex].tests[taskIndex - 1];
        if (prevTask && prevTask.type === 'expert') {
          result = {
            ...prevTask,
            respondentUuid: this.respondents[respondentIndex].uuid
          };
        } else {
          result = this.getPrevTask(taskIndex - 1, respondentIndex);
        }
      }

      return result;
    },

    getNextTask(taskIndex, respondentIndex) {
      let result = false;

      const tests = this.assessmentPlan.tests ?? this.assessmentPlan.structure;
      if (taskIndex < tests.length - 1) {
        const nextTask = tests[taskIndex + 1];

        if (nextTask && nextTask.type === 'expert') {
          result = {
            ...nextTask,
            respondentUuid: this.respondents[respondentIndex].uuid
          };
        } else {
          result = this.getNextTask(taskIndex + 1, respondentIndex);
        }
      } else if (respondentIndex < this.respondents.length - 1) {
        const nextRespondent = this.respondents[respondentIndex + 1];
        if (nextRespondent) {
          result = this.getNextTask(-1, respondentIndex + 1);
        }
      }

      return result;
    },

    changeTask(direction) {
      this.$refs.chatRef.checkToExistingNewMessage(direction === 'next' ? this.loadNextTask : this.loadPrevTask);
      this.activeTab = 'mark';
      this.setChatMessages([]);
    }
  },
  mounted() {
    if (this.isRespondent) {
      customAssessmentApi.startSession(this.planUuid, this.taskUuid)
          .then(response => {
            this.sessionUuid = response;
          });
    } else {
      const unWatch = watch(() => this.respondents, () => {
        this.sessionUuid = this.respondent?.tests.find((item) => item.uuid === this.taskUuid)?.session_uuid;
        unWatch();
      }, {deep: true})
    }
  },
  watch: {
    taskUuid() {
      this.sessionUuid = this.respondent?.tests.find((item) => item.uuid === this.taskUuid)?.session_uuid;
    }
  }
}
</script>

<style src="./style.scss" />