<template>
  <div
    :class="[
      'mt-file-view',
      {
        'mt-file-view_error': props.file.isError,
        'mt-file-view_loading': props.file.isLoading,
        'mt-file-view_readonly': props.readonly,
      }
    ]"
  >
    <div class="mt-file-view__row">
      <div class="mt-file-view__main">
        <div class="mt-file-view__extension">
          {{ props.file.extension }}
        </div>
        <div class="mt-file-view__info">
          <a :href="props.file.original_url" target="_blank" :download="props.file.name" class="mt-file-view__name">
            {{ props.file.name }}
          </a>
          <div class="mt-file-view__description">
            <span class="mt-file-view__description__error" v-if="props.file.isError">
              Ошибка загрузки &nbsp;
            </span>
            {{ size }}
          </div>
        </div>
      </div>
      <div class="mt-file-view__actions" v-if="!props.readonly">
        <mt-button
            class="mt-file-view__delete"
            @click="deleteFile()"
            color="light-red"
            icon
        >
          <trash-icon/>
        </mt-button>
      </div>
    </div>
    <mt-loading-animation-linear class="mt-file-view__progress" v-if="props.file.isLoading"/>
  </div>
</template>

<script setup lang="ts">
import TrashIcon from "@/components/icons/TrashIcon.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import MtLinearProgress from "@/components/UI/mtLinearProgress/mtLinearProgress.vue";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";

interface Props {
  file: any;
  readonly?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  file: {},
  readonly: false,
});

const emit = defineEmits(['delete']);

const size = computed(() => {
  const sizeInBytes = props.file.size;
  const sizeInKb = sizeInBytes / 1024;
  const sizeInMb = sizeInKb / 1024;

  if (sizeInMb > 1) {
    return `${sizeInMb.toFixed(2)} MB`;
  }
  if (sizeInKb > 1) {
    return `${sizeInKb.toFixed(2)} KB`;
  }
  return `${sizeInBytes} B`;
});
const deleteFile = () => {
  emit('delete');
};

</script>

<style lang="scss">
.mt-file-view {
  width: calc(50% - 8px);
  margin: 4px;
  position: relative;
  padding: 12px;
  background: #fafafa;
  border-radius: 4px;
  &_error {
    background-color: $light-red;
  }
  &_readonly {
    .mt-file-view__main {
      width: 100%;
    }
  }
  &__row {
    display: flex;
    align-items: center;
  }
  &__main {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: calc(100% - 40px);
  }
  &__extension {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $brown;
    color: #fff;
    font-size: 12px;
    @include flexCenter;
  }
  &__info {
    margin-left: 8px;
    width: calc(100% - 48px);
  }
  &__name {
    font-size: 12px;
    color: #252733;
    width: 100%;
    display: block;
    @include textEllipsis();
    margin-bottom: 3px;
    text-decoration: underline;
  }
  &__description {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #C5C7CD;
    &__error {
      color: $red;
    }
  }
  &__actions {
    margin-left: auto;
  }
  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    right: 0;
  }
}
</style>