<template>
  <div>
    <app-alert
        v-if="notFound"
        type="error"
    >
      Запись не найдена
    </app-alert>

    <div
        class="assessment-plan"
        v-else
    >
      <plans-default-skeleton v-if="isLoading" />

      <mtExpander
          class="assessment-plan__expander"
          v-else
      >
        <mtExpanderItem>
          <template #header>
            <h3 class="assessment-plan__expander__title">
              {{ assessmentPlan.name }}
            </h3>
            <div
                class="assessment-plan__expander__alarm"
                v-if="assessmentPlan.dateTo || assessmentPlan.dateFrom"
            >
              <mtSvg
                  class="assessment-plan__expander__alarm__icon"
                  name="timerfilled"
                  color="#fff"
              />
              Выполнить
              <template v-if="assessmentPlan.dateFrom">
                &nbsp;с {{ assessmentPlan.dateFrom }}
              </template>
              <template v-if="assessmentPlan.dateTo">
                &nbsp;до {{ assessmentPlan.dateTo }}
              </template>
            </div>
          </template>
          <template
              #appendHeader
              v-if="assessmentPlan.show_progress"
          >
            <div class="assessment-plan__expander__statistics">
              <statisticsLine
                  :height="8"
                  :progress="assessmentPlan.progress"
                  background-color="#fff"
              />
              <slot
                  name="headerStatistics"
                  v-bind:item="assessmentPlan"
              />
            </div>
          </template>
          <template #content>
            <div class="assessment-plan__expander__text">
              {{ assessmentPlan.description }}
            </div>
          </template>
        </mtExpanderItem>
        <mtExpanderItem v-if="assessmentPlan.files?.length">
          <template #header>
            <h3 class="assessment-plan__expander__title">
              Прикрепленные файлы
            </h3>
          </template>
          <template #content>
            <file-download-card
                :links="assessmentPlan.files"
                linkValue="originalUrl"
                linkLabel="fileName"
            />
          </template>
        </mtExpanderItem>
      </mtExpander>
      <slot v-bind="assessmentPlan" />
    </div>
  </div>
</template>

<script>
import statisticsLine from '@/components/workspace/assessmentPlans/plans/statisticsLine/index.vue'
import mtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue'
import mtSvg from '@/components/UI/mtSvg/index.vue'
import mtExpanderItem from '@/components/UI/mtExpander/mtExpanderItem.vue'
import mtExpander from '@/components/UI/mtExpander/mtExpander.vue'
import {mapActions, mapState} from 'vuex'
import FileDownloadCard from '@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue'
import PlansDefaultSkeleton
  from '@/components/workspace/assessmentPlans/plans/SkeletonLoaders/DefaultHeaderSkeleton.vue'
import AppAlert from '@/components/UI/AppAlert/AppAlert.vue'
import usePlansStore from "@/store/plans.store";
import plansStore from "@/store/plans.store";
import {storeToRefs} from "pinia";

export default {
  name: 'planDefault',
  components: {
    FileDownloadCard,
    mtExpander,
    mtExpanderItem,
    statisticsLine,
    mtSvg,
    mtLoadingAnimationLinear,
    PlansDefaultSkeleton,
    AppAlert,
  },
  data() {
    return {
      notFound: false,
    }
  },
  setup() {
    const plansStore = usePlansStore();
    const {plan} = storeToRefs(plansStore);

    return {
      plan
    }
  },
  computed: {
    ...mapState('assessmentPlans/plan', ['assessmentPlan', 'isLoading']),
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),

    async initBreadcrumbs() {
      if (!this.$route.meta.breadcrumbs) return

      await this.setBreadcrumbs([
        ...this.$route.meta.breadcrumbs,
        {
          value: this.assessmentPlan.name,
          link: this.$route.name,
        },
      ])
    },

    getPlan() {
      this.fetchPlan({
        uuid: this.$route.params.planUuid,
      })
          .then((data) => {
            this.plan = data;
          })
          .catch(({status}) => {
            if (status === 404) {
              this.notFound = true
            }
          })
          .finally(() => {
            this.initBreadcrumbs()
          })
    },
  },
  mounted() {
    this.getPlan()
  }
}
</script>

<style lang="scss">
.assessment-plan {
  .statistics-circle__desc {
    &__desc {
      @include max-sm {
        display: flex;
        align-items: center;
      }
    }
    &__subtitle {
      @include max-sm {
        margin-left: 6px;
      }
    }
  }
  &__expander {
    margin-bottom: 20px;

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.4px;
      color: #252733;
    }

    &__alarm {
      width: fit-content;
      background: #FAB56E;
      border-radius: 100px;
      padding: 5px 12px;
      font-weight: 800;
      font-size: 11px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      margin-top: 8px;

      &__icon {
        display: flex;
        margin-right: 10px;
      }
    }

    &__statistics {
      display: flex;

      @media screen and (max-width: 992px){
        position: unset;
      }
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #9FA2B4;

      ul {
        list-style-type: disc;
        padding-left: 20px;
      }
    }
  }

  &-cards-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
    @include max-sm {
      //margin: 0;
    }
  }
  &-alert {
    width: fit-content;
  }

  &__submit-btn {
    margin-top: 20px;
  }
}

@media all and (max-width: 767px) {
  .assessment-plan {
    &__expander {
      .expander {
        &__item {
          &__title {
            align-self: flex-start;
          }
        }
      }

      &__statistics {
        flex-direction: column;
      }
    }
  }
}

@media all and (max-width: 575px) {
  .assessment-plan {
    &__expander {
      &__statistics {
        flex-grow: 1;
        margin: 16px 0 0;

        &-circle {
          margin: 7.5px 0!important;
        }
      }
    }

    &__submit-btn {
      width: 100%;
    }
  }
}
</style>