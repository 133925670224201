<script>
import StarIcon from "@/components/icons/StarIcon.vue";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";
import ConfirmationDialog from "@/components/composite/confirmationDialog/confirmationDialog.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import AppTextarea from "@/components/UI/AppTextarea/AppTextarea.vue";
import SendIcon from "@/components/icons/SendIcon.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import FileDownloadCard from "@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue";
import ChatApi from "@/api/chat.api";
import AppDataTable from "@/components/UI/AppDataTable/AppDataTable.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import InfoBlock from "@/components/workspace/InfoBlock.vue";
import AppUploadedFile from "@/components/UI/AppUploadedFile.vue";
import useRespondentsStore from "@/store/respondents.store";
import customAssessmentApi from "@/api/custom-assessment.api";
import usePlansStore from "@/store/plans.store";
import {storeToRefs} from "pinia";

export default {
  name: 'CustomAssessmentChat',
  components: {
    AppUploadedFile,
    InfoBlock,
    AppTooltip,
    AppDataTable,
    SendIcon,
    LinkIcon,
    MtButton,
    AppTextarea,
    ConfirmationDialog,
    TrashIcon,
    FileDownloadCard,
    MtLoadingAnimationLinear,
    StarIcon
  },
  props: {
    sessionUuid: String
  },
  data: () => ({
    chatMessages: [],
    newMessage: {
      message: '',
      files: []
    },
    pendingActionAfterReset: null,
    isChatLoading: false,
    isMessageSending: false,
    isConfirmationDialogOpen: false
  }),
  setup() {
    const respondentsStore = useRespondentsStore();
    const plansStore = usePlansStore();
    const {plan} = storeToRefs(plansStore);

    return {
      respondentsStore,
      assessmentPlan: plan,
    }
  },
  computed: {
    ...mapGetters(['authUser', 'isRespondent']),

    task() {
      const tests = this.assessmentPlan.tests ?? this.assessmentPlan.structure;
      return tests?.find((item) => item.uuid === this.$route.params.taskUuid) ?? null;
    },

    allowedOnlyUploadFiles() {
      return !this.task?.input_text && this.task?.upload_files;
    }
  },
  methods: {
    ...mapMutations('assessmentPlans/plan/task', ['setChatMessages']),

    checkToExistingNewMessage(action) {
      if (this.newMessage.message || this.newMessage.files.length) {
        this.isConfirmationDialogOpen = true;
        this.pendingActionAfterReset = action;
      } else {
        action();
      }
    },
    confirm() {
      this.isConfirmationDialogOpen = false;
      this.pendingActionAfterReset();
    },
    uploadFiles(event) {
      let files = [];

      event.target.files.forEach((file) => {
        if (file.size > 10485760) {
          this.$toast.error(`Файл ${file.name} превышает максимальный размер 10 МБ`);
          return;
        }

        files.push(file);
      })

      this.newMessage.files = [...this.newMessage.files, ...files];
    },
    deleteFile(index) {
      this.newMessage.files.splice(index, 1);
    },
    async sendMessage() {
      if (this.isMessageSending || (this.newMessage.files.length === 0 && !this.newMessage.message)) return;

      let session = this.sessionUuid;
      if (!session) {
        if (this.isRespondent) {
          session = await customAssessmentApi.startSession(this.$route.params.planUuid, this.$route.params.taskUuid);
        } else {
          const {data} = await this.respondentsStore.getRespondentSession(this.$route.params.planUuid, this.$route.params.taskUuid, this.authUser.uuid);
          session = data;
        }
      }

      this.isMessageSending = true;
      ChatApi.sendMessage(
          this.$route.params.planUuid,
          this.$route.params.taskUuid,
          session,
          this.newMessage,
      )
          .then((message) => {
            this.newMessage.message = '';
            this.newMessage.files = [];
            this.chatMessages.push(message);
            this.scrollToEndOfChat()
            setTimeout(() => {
              this.$refs.textarea.calculateHeight();
            })
          })
          .catch((error) => {
            if (error?.data?.message) {
              this.$toast.error(error?.data?.message)
            }
          })
          .finally(() => {
            this.isMessageSending = false;
          });
    },
    scrollToEndOfChat() {
      this.$nextTick(() => {
        const chat = this.$refs.chat;
        chat?.scroll(0, chat.scrollHeight);
      });
    },
    async loadMessages() {
      this.chatMessages = [];
      if (!this.sessionUuid) return;
      this.isChatLoading = true;

      return await ChatApi.getMessages(
          this.$route.params.planUuid,
          this.$route.params.taskUuid,
          this.sessionUuid
      )
          .then(response => {
            this.isChatLoading = false;
            this.chatMessages = response.data.data.reverse();

            this.setChatMessages(this.chatMessages);

            this.$nextTick(() => {
              this.scrollToEndOfChat();
            })
          })
          .finally(() => {
            this.isChatLoading = false
          })
    },
  },
  mounted() {
    this.loadMessages();
  },
  watch: {
    sessionUuid() {
      this.loadMessages();
    },

    chatMessages: {
      handler(newValue) {
        this.$emit('update-count', newValue.length)
      },
      deep: true
    }
  }
}
</script>

<template>
  <div class="custom-assessment__chat">
    <mt-loading-animation-linear v-if="isChatLoading" />

    <template v-else>
      <info-block
          class="custom-assessment__chat__no-data"
          v-if="!chatMessages.length"
          title="Обсуждение еще не начато"
      />

      <div
          ref="chat"
          v-if="chatMessages.length"
          class="custom-assessment__chat__list"
      >
        <div
            :class="[
                'custom-assessment__chat__item',
                {
                  'custom-assessment__chat__item_me': message.user.uuid === authUser.uuid
                }
            ]"
            v-for="message in chatMessages"
            :key="message.uuid"
        >
          <div class="custom-assessment__chat__item__header">
            <h3 class="custom-assessment__chat__item__title">{{ message.user.fullName }} ({{ message.user.role }})</h3>
            <p class="custom-assessment__chat__item__date">{{ message.createdAt }}</p>
          </div>
          <p class="custom-assessment__chat__item__text">{{ message.value }}</p>

          <app-uploaded-file
              v-for="(file, index) in message.files"
              :key="index"
              :file
              url-key="originalUrl"
              name-key="fileName"
              bg-color="#fff"
          />
        </div>
      </div>

      <div class="custom-assessment__input-box">
        <input
            ref="newMessageFileInput"
            type="file"
            multiple="multiple"
            style="display: none;"
            @change="uploadFiles"
        />
        <mt-loading-animation-linear
            :class="[
              'custom-assessment__message-send-loading',
              {
                'custom-assessment__message-send-loading_show': isMessageSending
              }
            ]"
        />

        <div
            v-if="newMessage.files.length"
            class="custom-assessment__input-box__files"
        >
          <h4 class="custom-assessment__input-box__files__title">Файлы для отправки</h4>

          <app-uploaded-file
              v-for="(file, index) in newMessage.files"
              :key="index"
              bg-color="#fff"
              :file
              :delete-fn="() => deleteFile(index)"
          />
        </div>

        <div class="custom-assessment__input-box__wrap">
          <app-textarea
              v-model="newMessage.message"
              ref="textarea"
              :disabled="!task?.input_text"
              :placeholder="allowedOnlyUploadFiles ? 'Разрешена только загрузка файлов' : !task?.input_text ? 'Вам запрещено отправлять сообщения' : 'Отправить сообщение ...'"
              class="custom-assessment__input-box__textarea"
              :loading="isMessageSending"
              :initial-height="25"
              @submit="sendMessage()"
          />
          <mt-button
              v-if="task?.upload_files"
              icon
              color="white"
              class="custom-assessment__input-box__btn"
              @click="$refs.newMessageFileInput.click()"
          >
            <link-icon />
          </mt-button>
          <mt-button
              icon
              color="white"
              class="custom-assessment__input-box__btn"
              :disabled="isMessageSending"
              @click="sendMessage()"
          >
            <send-icon />
          </mt-button>
        </div>
      </div>
    </template>

    <confirmation-dialog
        v-model="isConfirmationDialogOpen"
        text="Вы уверены, что хотите перейти к другому заданию?"
        submit-text="Перейти"
        :handler="confirm"
    >
      <template #title>
        Введенное вами сообщение будет потеряно
      </template>
    </confirmation-dialog>
  </div>
</template>

<style lang="scss">
.custom-assessment__chat {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__list {
    margin-bottom: auto;
  }

  &__no-data {
    margin: auto 0;
  }

  &__item {
    margin-bottom: 10px;
    padding: 15px;
    background: $light-gray;
    border-radius: 12px;

    &_me {
      background-color: $light-green;
    }

    &_mark {
      background-color: $light-green;
    }

    &__header {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      margin-bottom: 5px;
    }

    &__title {
      flex-grow: 1;
      color: #00788C;
    }

    &__star {
      color: $green;
      margin-right: 8px;
    }

    &__date {
      white-space: nowrap;
      color: #9FA2B4;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #252733;
      margin-top: 5px;
      white-space: pre-wrap;
      word-break: break-word;

      &:not(&:last-child) {
        margin-bottom: 10px;
      }
    }

    &__chips-row {
      display: flex;
      flex-wrap: wrap;
      margin: 10px -5px 0;

      & + & {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    &__chip {
      display: flex;
      margin: 5px;
      background-color: #fff;
      padding: 10px 15px;
      border-radius: 12px;
      font-weight: 600;
      font-size: 14px;
      color: #00788C;
      align-items: center;

      span {
        margin-left: 8px;
        padding: 2px 5px;
        border: 2px solid #00788C;
        border-radius: 3px;
        line-height: 1;
        font-weight: bold;
      }
    }
  }


}
</style>