<template>
  <div class="support__container">
    <label class="page-title">Обращение в техническую поддержку</label>

    <div class="input__container">
      <app-input
          v-model="title"
          hide-details
          :errors="isInvalidTitle"
      >
        <template #label>
          Тема <span class="required">*</span>
        </template>
      </app-input>
      <span
          class="error-message"
          v-show="isInvalidTitle"
      >
        Заполните поле “Тема”
      </span>
    </div>
    <div class="input__container">
      <app-textarea
          v-model="description"
          hide-details
          :errors="isInvalidDesc"
      >
        <template #label>
          Текст <span class="required">*</span>
        </template>
      </app-textarea>
      <span
          class="error-message"
          v-show="isInvalidDesc"
      >
        Заполните поле “Текст”
      </span>
    </div>

    <div class="attached">
      <app-files-uploader
          label="Прикрепленные файлы"
          v-model="files"
      />
    </div>
    <div class="btn-container">
      <app-tooltip :disabled="!hasUploadingFiles">
        <template #activator>
          <mt-button
              :disabled="hasUploadingFiles"
              :loading="isLoading"
              @click="sendAppeal()"
          >
            Отправить
          </mt-button>
        </template>

        Пожалуйста, дождитесь окончания загрузки файлов
      </app-tooltip>
    </div>
  </div>
</template>

<script>
import mtInput from "@/components/UI/mtInput/mtInput.vue";
import mtTextarea from "@/components/UI/mtTextarea/index.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import mtIcon from "@/components/UI/mtIcon/mtIcon.vue";
import { mapActions } from "vuex";
import AppFilesUploader from "@/components/composite/AppFilesUploader.vue";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import AppTextarea from "@/components/UI/AppTextarea/AppTextarea.vue";
import {watch} from "vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";

export default {
  components: {
    AppTooltip,
    AppTextarea,
    AppInput,
    AppFilesUploader,
    mtInput,
    mtTextarea,
    mtButton,
    mtIcon
  },
  data() {
    return {
      title: '',
      description: '',
      files: [],
      isInvalidTitle: false,
      isInvalidDesc: false,
      isLoading: false
    }
  },
  computed: {
    hasUploadingFiles() {
      return this.files.some(x => x.isLoading)
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),

    async sendAppeal() {
      this.checkForm();

      if (this.isInvalidTitle || this.isInvalidDesc) {
        const unWatch = watch(() => [this.title, this.description], () => {
          this.checkForm();
          if(!this.isInvalidTitle && !this.isInvalidDesc) {
            unWatch();
          }
        });
        return;
      }

      this.isLoading = true;
      await this.$workspaceApi.support.sendAppeal({
        title: this.title,
        description: this.description,
        files: this.files.map(x => x.uuid)
      })
          .then(() => {
            this.title = '';
            this.description = '';
            this.files = [];

            this.$toast.success("Письмо успешно отправлено!")
          })
          .finally(() => this.isLoading = false);
    },

    checkForm() {
      this.isInvalidTitle = !this.title.trim().length;
      this.isInvalidDesc = !this.description.trim().length;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.scrollToTop();
  }
}
</script>

<style
    scoped
    lang="scss"
>
.support__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  padding: 32px;

}

.input {
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .error-message {
      color: rgb(194 64 32);
      margin-top: 3px;
      font-size: 12px;
      min-height: 14px;
    }
  }

  &__label {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #7C869D;
    display: flex;
    align-items: center;
    gap: 3px;

    .required {
      color: red;
    }
  }
}

.attached {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .text {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #252733;
    }

    .arrow {
      display: none;
    }

  }

  .files {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    background: #F0F1F7;
    border-radius: 12px;
    padding: 10px 15px;
    overflow: hidden;
    margin-bottom: 22px;

    &__item {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-decoration-line: underline;
      color: #252733;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .file-name {
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 90%;
      }

      .close {
        width: 14px;
        height: 14px;
        opacity: 0.7;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .add-file {
    &__btn {
      display: flex;
      align-items: center;
      padding: 5px 0;
      cursor: pointer;
      gap: 17px;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #00788C;
      width: max-content;


    }

    &__icon {
      width: 24px;
      height: 24px;
    }
  }
}

.page-title {
  display: none;
}

.btn-container {
  display: flex;
  align-items: center;
  gap: 10px;
}


@media screen and (max-width: 576px) {

  .header__page-info__details {
    display: none;
  }
  .support__container {
    border: 0;
    padding: 0;
  }

  .input {
    width: 100%;
    max-width: unset;
  }
  .page-title {
    display: flex;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #252733;
  }
  .btn-container {

    .mt-button_green {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 13px 25px;
      width: 100%;
      height: 50px;
      background: #00788C;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      &.mt-button_light-green {
        display: none;
      }
    }
  }
  .attached {

    &__title {
      display: flex;
      justify-content: space-between;


      .arrow {
        display: flex;
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
        transition: 0.5s;

        &.isOpen {
          transform: rotate(0deg);
        }
      }

    }
  }
}
</style>