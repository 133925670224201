<template>
  <app-steps :steps="steps"/>

  <mt-loading-animation-linear v-if="!isLoaded" />

  <router-view v-else v-slot="{ Component }">
    <component :is="Component" />
  </router-view>

</template>

<script>

import { mapActions, mapGetters } from "vuex";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import AppSteps from "@/components/UI/AppSteps/AppSteps.vue";

export default {
  name: 'planAdmin',
  components: {AppSteps, MtLoadingAnimationLinear },
  data() {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters('assessmentPlans/plan',["planEditdataExd"]),
    steps() {
      return [
        {
          name: 'Структура плана оценки',
          to: 'AddEdit1',
        },
        {
          name: 'Настройка параметров оценки',
          to: 'AddEdit2',
        },
        {
          name: 'Настройка уровней интерпретации',
          to: 'AddEdit3',
        },
        {
          name: 'Участники плана оценки',
          to: 'AddEdit4',
        }
      ]
    }

  },
  methods: {
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),

    async initBreadcrumbs() {
      if(!this.$route.meta.breadcrumbs) return;

      await this.setBreadcrumbs([
        ...this.$route.meta.breadcrumbs,
        { value: this.planEditdataExd.name }
      ])
    }
  },
  mounted() {
    this.fetchPlan({ uuid: this.$route.params.planUuid })
      .then(() => {
        this.initBreadcrumbs();
        this.isLoaded = true;
      })

    if(this.$route.name === 'ExpRespAssessmentPlanSingle') {
      this.$router.push({
        name: 'AddEdit1',
        params: {
          uuid: this.$route.params.planUuid
        }
      })
    }
  },
  watch: {
    '$route': {
      handler(newValue) {
        newValue.matched.forEach(item => {
          if(item.name === "ExpRespAssessmentPlanSingle") this.initBreadcrumbs();
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.mt-data-table {
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  position: relative;
  width: fit-content;
  max-width: 100%;
  transition: .3s opacity;

  &__body {
    position: relative;
  }

  &_loading {
    opacity: 0.7;
    pointer-events: none;
  }

  &__sort-icon {
    cursor: pointer;
    transition: transform .3s;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__cell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    &_fullname {
      white-space: nowrap;
    }

    &__notification {
      width: 16px;
      margin-left: 8px;
    }
    &__more {
      color: $black;
      flex-shrink: 0;
      &_failed {
        color: #C24020;
      }
      &_expert {
        text-decoration: underline;
      }
    }
  }

  a.mt-data-table__cell_fullname {
    color: #000;
    text-decoration: underline;
    transition: color .3s;

    &:hover {
      color: #00788C;
    }
  }

  &__edit {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    svg {
      fill: $green;
    }
  }
  &__show-more {
    position: absolute;
    width: 200px;
    height: 100%;
    background: linear-gradient(to right, transparent, #ffffff);
    top: 0;
    right: 0;
    padding: 0;
    text-align: right;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    pointer-events: none;
    &_available {
      opacity: 1;
      visibility: visible;
    }
  }
  &__header {
    height: 57px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    &__toggler {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 8px solid $green;
      margin-left: 10px;
      &_active {
        transform: scale(-1);
      }
    }
  }

  &__wrapper {
    overflow-x: auto;
  }

  th, td {
    padding: 12px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #252733;
    vertical-align: top;
  }

  th {
    white-space: nowrap;
    background: #F0F1F7;
    box-shadow: inset 0px -1px 0px #E6E7E9;
  }

  tbody tr > * {
    border-bottom: 1px solid #DFE0EB;
    min-width: 130px;
  }

  table {
    width: 100%;
  }

  &__header {
    &__icon {
      cursor: pointer;
      svg {
        width: 20px;
        height: 18px;
      }

      &_pre {
        margin-right: 16px;
      }
    }
  }

  &__border-right {
    border-right: 2px solid #E6E7E9;
  }

  &__border-left {
    border-left: 2px solid #E6E7E9;
  }

  &__pagination {
    justify-content: flex-end;
    min-height: 60px;
  }
}

.expert-plan {
  &__respondent {
    text-decoration: underline;
    white-space: nowrap;
  }

  &__export-btn {
    background: #F0F1F7;
    border-radius: 8px;
    height: 33px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>