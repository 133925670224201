<template>
  <mt-expander>
    <assessment-plans-row
        v-for="(row, index) in rows"
        :key="index"
        :assessment-plans="row.plans"
        @update="updateAssessmentPlansList"
    >
      <template
          v-if="row.title"
          #header
      >
        {{ row.title }}
      </template>
      <template #item-statistics="assessmentPlan">
        <slot
            name="item-statistics"
            v-bind="assessmentPlan"
        />
      </template>
    </assessment-plans-row>
  </mt-expander>
</template>

<script>
import assessmentPlansRow from '@/components/workspace/assessmentPlans/plans/row/index.vue';
import mtExpander from '@/components/UI/mtExpander/mtExpander.vue';
import {mapActions} from "vuex";

export default {
  name: 'assessmentPlansRows',
  components: {mtExpander, assessmentPlansRow},
  props: {
    rows: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchActivePlans', 'fetchCompletedPlans', 'fetchArchivePlans']),

    updateAssessmentPlansList({name, meta}) {
      const params = {
        per_page: meta.per_page,
        page: meta.current_page + 1
      }
      if (name === 'completed') {
        this.fetchCompletedPlans(params)
      } else if (name === 'active') {
        this.fetchActivePlans(params)
      } else if (name === 'archive') {
        this.fetchArchivePlans(params)
      }
    }
  }
}
</script>


<style
    lang="scss"
    src="./style.scss"
/>
