<script setup lang="ts">
import Datepicker from "@vuepic/vue-datepicker";
import type { Ref } from "vue";
import { computed, ref, watch } from "vue";
import { format, parse } from "date-fns";
import { addLeadingZero } from "@/services/utils.js";
import { createDateFromDDMMYYY } from "@/services/date.js";
import AppFormBlock from "@/components/UI/AppFormBlock/AppFormBlock.vue";

enum DateTypeEnum {
  Date = "date",
  Time = "time",
  DateTime = "dateTime",
}

type Props = {
  modelValue: string | null
  type?: DateTypeEnum
  readonly?: boolean
  minDateTomorrow?: boolean
  fromDate?: string
  maxDate?: string
  minDate?: string
  toDate?: string
  format?: string
  label?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  type: DateTypeEnum.Date,
  readonly: false
});

const emit = defineEmits(["update:modelValue"]);

const computedFormat: Ref<string> = computed(() => {
  if (props.format) return props.format;
  if (typeIsDateTime.value) return "dd-MM-yyyy, HH:mm";
  if (typeIsOnlyTime.value) return "HH-mm";
  return "dd-MM-yyyy";
});

const date = ref<Date | null>(null);

function getFormattedDate(value: string | null) {
  if (!value) return null;
  const dateInstance = createDateFromDDMMYYY(value);
  return format(dateInstance, computedFormat.value);
}

// Смотрим за изменениями modelValue и обновляем локальную переменную date
watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      date.value = parse(newValue, `${props.format || "dd.MM.yyyy"}`, new Date());
    } else {
      date.value = null;
    }
  },
  { immediate: true } // Запускаем watch сразу при монтировании
);

const minDate = computed(() => {
  if (!props.minDate) return;
  return parse(props.minDate, computedFormat.value, new Date).toISOString();
});

const maxDate = computed(() => {
  if (!props.maxDate) return;
  // return parse(props.maxDate, computedFormat.value, new Date).toISOString();
  try {
    return parse(props.maxDate, computedFormat.value, new Date).toISOString();
  } catch (e) {
    return (new Date).toISOString();
  }
});

const typeIsOnlyTime = computed(() => {
  return props.type === "time";
});

const typeIsDateTime = computed(() => {
  return props.type === "dateTime";
});

function setDate() {
  if (date.value === null) {
    emit("update:modelValue", null);
    return;
  }
  if (props.type === "time") {
    date.value.hours = addLeadingZero(date.value.hours);
    date.value.minutes = addLeadingZero(date.value.minutes);
    emit("update:modelValue", `${date.value.hours}:${date.value.minutes}`);
  } else if (props.type === "date") {
    emit("update:modelValue", format(new Date(date.value), props.format || "dd.MM.yyyy"));
  } else if (props.type === "dateTime") {
    emit("update:modelValue", format(new Date(date.value), props.format || "dd.MM.yyyy"));
  } else {
    emit("update:modelValue", date.value.toISOString());
  }
}

const isOpened = ref(false);

</script>

<template>
  <app-form-block class="date-time-picker">
    <label
      :class="[
        'date-time-picker__label',
        {
          'date-time-picker__label--active': isOpened || date,
        },
      ]"
    >
      {{ props.label }}
      <slot name="label" />
    </label>
    <datepicker
      auto-apply
      :time-picker-inline="typeIsOnlyTime || typeIsDateTime"
      v-model="date"
      :enable-time-picker="typeIsOnlyTime || typeIsDateTime"
      :format="computedFormat"
      :min-date="minDate"
      :max-date="maxDate"
      :readonly="props.readonly"
      :time-picker="typeIsOnlyTime"
      :text-input="true"
      :ui="{ input: 'date-time-picker__input' }"
      month-name-format="long"
      locale="ru"
      utc
      @update:modelValue="setDate()"
      @open="() => isOpened = true"
      @close="() => isOpened = false"
    />
  </app-form-block>
</template>

<style lang="scss" src="./AppDateTimePicker.scss" />