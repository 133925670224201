export const makeFileItem = (item) => {
  return {
    uuid: item.uuid,
    fileName: item.name + '.' + item.extension,
    name: item.name + '.' + item.extension,
    originalUrl: item.original_url,
    extension: item.extension,
    size: item.size
  }
}
export const makeFileItems = (files) => {
  if(!files) return [];

  return files.map(file => makeFileItem(file));
}