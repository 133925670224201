import {format, isSameYear} from 'date-fns';
import { ru } from 'date-fns/locale';

export function formatDateTimeByDefault (date) {
  return formatDate(date, 'dd MMMM yyyy, HH:mm')
}

export function formatDateByDefault (date) {

  return formatDate(date, 'dd MMMM, yyyy')
}
export function formatDate (date, formatStr = 'PP') {
  if(!date) return null;

  return format(new Date(date), formatStr, {
    locale: ru
  })
}
export function formatDateTime (date, formatStr = 'PP') {
  if(!date) return null;

  return format(new Date(date), formatStr, {
    locale: ru
  })
}
export function formatDateOfItemByDefault (date, item) {
  let format = 'dd MMM';

  const isFromToSameYear = isSameYear(new Date(item?.date_from), new Date(item?.date_to));
  if(!isFromToSameYear) format += ' Y';

  return formatDate(date, format)
}

export function formatDateTimeOfItemByDefault (date, item) {
  let format = 'dd MMM HH:mm';

  const isFromToSameYear = isSameYear(new Date(item?.date_from), new Date(item?.date_to));
  if(!isFromToSameYear) format += ' Y';

  return formatDateTime(date, format)
}

export function createDateFromDDMMYYY(dateString, splitter = '.') {
  let dateParts = dateString.split(splitter);
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
}
