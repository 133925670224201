import api from '@/network';
import {
    chatMessageIndexInputAdapter,
    chatMessageSingleInputAdapter
} from "@/adapters/assessmentPlans/tasks/messages/chatMessageIndexInputAdapter";

export default {
    async sendMessage(
        planUuid: string,
        taskUuid: string,
        sessionUuid: string,
        payload: any,
        signal: AbortSignal
    ) {

        let formData = new FormData();
        formData.append('message', payload.message);
        payload.files.forEach((file) => {
            formData.append('file[]', file);
        })

        const response = await api.post(
            `/plans/${ planUuid }/tests/${ taskUuid }/session/${ sessionUuid }/message`,
            formData,
            {
                signal
            }
        );
        return chatMessageSingleInputAdapter(response.data.data);
    },

    async getMessages(
        planUuid: string,
        taskUuid: string,
        sessionUuid: string,
        queryParams: any
    ) {
        const response = await api.get(`/plans/${ planUuid }/tests/${ taskUuid }/session/${ sessionUuid }/chat`, {
            params: queryParams
        });
        response.data.data = chatMessageIndexInputAdapter(response.data.data)

        return response;
    }

}