<template>
  <planDefault>
    <template #headerStatistics>
      <statistics-amount
          class="assessment-plan__expander__statistics-circle"
          :amount="assessmentPlan.participantsAmount"
      />
    </template>
    <template v-slot:default="assessmentPlan">
      <mt-loading-animation-linear
          class="mb-1"
          :style="{'opacity': respondentsLoading ? '1' : '0'}"
      />
      <Alert
          v-if="computedDateAlert"
          class="mb-4"
          type="warning"
      >
        {{ computedDateAlert }}
      </Alert>
      <Alert
        class="mb-4"
        type="warning"
        v-if="assessmentPlan.isStopped"
      >
        План оценок остановлен
      </Alert>
      <Alert
          class="mb-4"
          v-if="assessmentPlan.completed"
          type="warning"
      >
        План оценок завершен
      </Alert>

      <expert-review-table />

      <mt-button
          v-if="isExpert"
          class="assessment-plan__submit-btn"
          @click="openConfirmationToResume"
      >
        {{ assessmentPlan.completed ? 'Возобновить' : 'Завершить' }}
      </mt-button>

      <teleport to="body">
        <confirmation-dialog
            v-model="isConfirmationDialogOpen"
            :title="computedTextForConfirmationDialog.title"
            :text="computedTextForConfirmationDialog.text"
            :handler="runCompletePlanAction"
        />
      </teleport>
    </template>
  </planDefault>
  <router-view :key="routerKey"/>
</template>

<script>
import planDefault from '@/components/workspace/assessmentPlans/plans/plan/DefaultPlanSingle.vue';
import statisticsAmount from '@/components/workspace/assessmentPlans/plans/statisticsAmount/index.vue';
import ConfirmationDialog from '@/components/composite/confirmationDialog/confirmationDialog.vue';
import {mapActions, mapGetters} from 'vuex';
import MtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue';
import Alert from '@/components/UI/AppAlert/AppAlert.vue';
import ExpertReviewTable from "@/components/workspace/ExpertReviewTable.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import usePlansStore from "@/store/plans.store";
import {storeToRefs} from "pinia";

export default {
  name: 'planExpert',
  components: {
    ExpertReviewTable,
    Alert,
    MtLoadingAnimationLinear,
    ConfirmationDialog,
    mtButton,
    statisticsAmount,
    planDefault
  },
  data: () => ({
    taskDialog: false,
    taskLoading: true,

    chosenTask: {},
    routerKey: 1,

    reports: [
      {
        variant: 0,
        selected: false,
        title: 'для респондента'
      }
    ],

    isConfirmationDialogOpen: false,
    isExpertAssessmentDialogOpen: false,

    clipboardTask: null,
    activeTestColumn: null,

    respondentsLoading: false,
  }),
  setup() {
    const plansStore = usePlansStore();
    const {plan} = storeToRefs(plansStore);

    return {
      assessmentPlan: plan
    }
  },
  computed: {
    ...mapGetters(['isExpert']),

    computedTextForConfirmationDialog () {
      const title = this.assessmentPlan.completed ? 'Возобновить' : 'Завершить'
      const text = this.assessmentPlan.completed ? 'Возобновить работу с планом оценки?' : 'Завершить работу с планом оценки?'

      return {title, text}
    },

    computedExpiredDate () {
      const today = new Date()
      const dateFrom = this.assessmentPlan.date_from ? new Date(this.assessmentPlan.date_from) : null
      const dateTo = this.assessmentPlan.date_to ? new Date(this.assessmentPlan.date_to) : null

      if(dateFrom?.valueOf() > today.valueOf()) {
        return false
      }

      if(dateTo?.valueOf() < today.valueOf()) {
        return true
      }

      return null
    },

    computedDateAlert() {
      if(this.computedExpiredDate === null) return null;

      return !this.computedExpiredDate ? 'План оценки станет доступен позднее.' : 'Истёк срок работы с данным планом оценки'
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['completePlan', 'resumePlan']),

    async runCompletePlanAction() {
      if(!this.assessmentPlan.completed) {
        await this.completePlan(this.assessmentPlan.uuid).then(() => {
          this.isConfirmationDialogOpen = false;
        });
      } else {
        await this.resumePlan(this.assessmentPlan.uuid).then(() => {
          this.isConfirmationDialogOpen = false;
        });
      }

      return Promise.resolve();
    },

    openConfirmationToResume() {
      this.isConfirmationDialogOpen = true
    }
  }
}
</script>

<style lang="scss">
.mt-data-table {
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  position: relative;
  width: fit-content;
  max-width: 100%;
  transition: .3s opacity;

  &__body {
    position: relative;
  }

  &_loading {
    opacity: 0.7;
    pointer-events: none;
  }

  &__sort-icon {
    cursor: pointer;
    transition: transform .3s;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__cell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    &_fullname {
      white-space: nowrap;
    }

    &__notification {
      width: 16px;
      margin-left: 8px;
    }
    &__more {
      color: $black;
      flex-shrink: 0;
      &_failed {
        color: #C24020;
      }
      &_expert {
        text-decoration: underline;
      }
    }
  }

  a.mt-data-table__cell_fullname {
    color: #000;
    text-decoration: underline;
    transition: color .3s;

    &:hover {
      color: #00788C;
    }
  }

  &__edit {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    svg {
      fill: $green;
    }
  }
  &__show-more {
    position: absolute;
    width: 200px;
    height: 100%;
    background: linear-gradient(to right, transparent, #ffffff);
    top: 0;
    right: 0;
    padding: 0;
    text-align: right;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    pointer-events: none;
    &_available {
      opacity: 1;
      visibility: visible;
    }
  }
  &__header {
    height: 57px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    &__toggler {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 8px solid $green;
      margin-left: 10px;
      &_active {
        transform: scale(-1);
      }
    }
  }

  &__wrapper {
    overflow-x: auto;
  }

  th, td {
    padding: 12px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #252733;
    vertical-align: top;
  }

  th {
    white-space: nowrap;
    background: #F0F1F7;
    box-shadow: inset 0px -1px 0px #E6E7E9;
  }

  tbody tr > * {
    border-bottom: 1px solid #DFE0EB;
    min-width: 130px;
  }

  table {
    width: 100%;
  }

  &__header {
    &__icon {
      cursor: pointer;
      svg {
        width: 20px;
        height: 18px;
      }

      &_pre {
        margin-right: 16px;
      }
    }
  }

  &__border-right {
    border-right: 2px solid #E6E7E9;
  }

  &__border-left {
    border-left: 2px solid #E6E7E9;
  }

  &__pagination {
    justify-content: flex-end;
    min-height: 60px;
  }
}

.expert-plan {
  &__respondent {
    text-decoration: underline;
    white-space: nowrap;
  }

  &__export-btn {
    background: #F0F1F7;
    border-radius: 8px;
    height: 33px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>